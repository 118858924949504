import { useState } from "react";
import { RiMailLine, RiUserLine, RiUserSettingsLine } from "react-icons/ri";
import { Control, Controller, FieldError } from "react-hook-form";
import { Button, Select } from "antd";
import { GoChevronUp } from "react-icons/go";
import { Colors } from "constants/colors";
import {
  ContainerBorder,
  CardAccordion,
  DataDropDown,
  Form,
  FormRow,
  SelectContainer,
  SelectOption,
  OptionHeader,
  RemoveContainer,
} from "./styles";
import { InputForm } from "components/Form/InputForm";
import { FormSchema, ROLES } from "../../misc";
import { UseFormRegister } from "react-hook-form";
import { TeamUsersRoleEnum } from "services/teams/types";
import { BiTrash } from "react-icons/bi";

const { Option } = Select;

interface AccordionNewCollaboratorProps {
  register: UseFormRegister<FormSchema>;
  index: number;
  collaborator?: {
    name?: string;
    email?: string;
    role?: TeamUsersRoleEnum;
  };
  control: Control<FormSchema, object>;
  errors?: {
    collaborators?:
      | {
          name?: FieldError | undefined;
          email?: FieldError | undefined;
          role?: FieldError | undefined;
        }[]
      | undefined;
  };
  defaultOpen?: boolean;
  onRemove: (index: number) => void;
}

function AccordionNewCollaborator({
  register,
  index,
  collaborator,
  control,
  errors,
  defaultOpen = false,
  onRemove,
}: AccordionNewCollaboratorProps) {
  const [open, setOpen] = useState(defaultOpen);

  const handleToggleActive = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <CardAccordion
        onClick={handleToggleActive}
        active={open}
        title={`Colaborador ${index + 1}`}
      >
        <div className="content">
          <div className="texts">
            <h2>Colaborador {index + 1}</h2>
            {collaborator?.name ? <p>{collaborator?.name}</p> : null}
          </div>
        </div>

        <GoChevronUp size={22} color={Colors.secondary50} />
      </CardAccordion>

      {open && (
        <DataDropDown>
          <ContainerBorder>
            <Form>
              <FormRow>
                <InputForm
                  isFull
                  icon={<RiUserLine size={22} color={Colors.secondary40} />}
                  label="Nome do colaborador"
                  placeholder="Insira o nome do colaborador..."
                  {...register(`collaborators.${index}.name`)}
                  error={
                    errors ? errors?.collaborators?.at(index)?.name : undefined
                  }
                />
                <InputForm
                  isFull
                  icon={<RiMailLine size={22} color={Colors.secondary40} />}
                  label="E-mail do colaborador"
                  placeholder="Insira o e-mail do colaborador..."
                  {...register(`collaborators.${index}.email`)}
                  error={
                    errors ? errors?.collaborators?.at(index)?.email : undefined
                  }
                />
              </FormRow>

              <SelectContainer>
                <p className="label">Tipo de acesso</p>

                <Controller
                  name={`collaborators.${index}.role`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      optionLabelProp="title"
                      placeholder="Insira o tipo de acesso ou selecione um..."
                      onChange={(e) => {
                        const role = ROLES.find(
                          (r) => r.id === (e as unknown as string)
                        )?.id;

                        onChange(role);
                      }}
                      value={ROLES.find((r) => r.id === value)?.title}
                    >
                      {ROLES.map((role) => (
                        <Option key={role.id} value={role.id}>
                          <SelectOption>
                            <OptionHeader>
                              <RiUserSettingsLine
                                color={Colors.primary100}
                                size={20}
                              />
                              <strong>{role.title}</strong>
                            </OptionHeader>

                            <span>{role.description}</span>
                          </SelectOption>
                        </Option>
                      ))}
                    </Select>
                  )}
                />

                {errors && errors?.collaborators?.at(index)?.role && (
                  <span className="error">
                    {errors?.collaborators?.at(index)?.role?.message}
                  </span>
                )}
              </SelectContainer>

              <RemoveContainer>
                <Button
                  onClick={() => onRemove(index)}
                  danger
                  type="ghost"
                  icon={<BiTrash />}
                />
              </RemoveContainer>
            </Form>
          </ContainerBorder>
        </DataDropDown>
      )}
    </>
  );
}

export default AccordionNewCollaborator;

import { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import debounce from "lodash/debounce";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Divider } from "antd";
import {
  RiAddFill,
  RiCalendarCheckFill,
  RiCloseCircleFill,
  RiCloseCircleLine,
  RiTeamLine,
} from "react-icons/ri";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import {
  BoxHeader,
  Container,
  Box,
  Title,
  ActionsContainer,
  ConfirmButton,
  CancelButton,
  EventsContainer,
  TeamColorWrapper,
  TeamColorContainer,
  TeamColorButton,
  InputWrapper,
  Input,
  Autocomplete,
  AutocompleteOption,
  ModalStyled,
  FieldWrapper,
} from "./styles";
import { Footer } from "components/Footer";
import { InputForm } from "components/Form/InputForm";
import { Colors } from "constants/colors";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { SelectEventFilter } from "components/FiltersSelect/SelectEventFilter";
import ItemSelected, { ItemsSelectedContainer } from "components/ItemSelected";
import { ModalSuccess } from "components/ModalSuccess";
import { useDisclosure } from "hooks/useDisclosure";
import { doCreateTeam, handleSearchMemberByEmail } from "services/teams";
import {
  convertToTeamRole,
  EventDTO,
  UserDTO,
  UserResponse,
} from "services/teams/types";
import { EventOrganizer } from "types";
import { BiUserCircle } from "react-icons/bi";
import ModalSelectUserRole from "./components/ModalSelectUserRole";
import { TeamUsersRoleEnum } from "services/teams/types";
import { errorNotification } from "components/Notification";
import axios from "axios";
import { isEmailValid } from "utils/validateEmail";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";

const metaTags = {
  title: `Cadastro de time - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

type FormSchema = {
  name: string;
  events: Array<EventDTO>;
  users: Array<UserDTO>;
  color: string;
};

type MemberOption = {
  label: React.ReactNode | JSX.Element;
  value: string;
  user: UserResponse;
};

const formSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  events: yup
    .array()
    .required("Selecione pelo menos um evento")
    .min(1, "Selecione pelo menos um evento"),
  users: yup
    .array()
    .required("Adicione pelo menos um integrante")
    .min(1, "Adicione pelo menos um integrante"),
  color: yup.string().required("Cor obrigatória"),
});

const emailSchema = yup.string().email();

function TeamRegister() {
  const navigate = useNavigate();
  const [options, setOptions] = useState<Array<MemberOption>>([]);
  const [searchValue, setSearchValue] = useState("");
  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();
  const [selectedEvents, setSelectedEvents] = useState<Array<EventOrganizer>>(
    []
  );
  const [showHexEditor, setShowHexEditor] = useState(false);
  const [isOpenModalSelectUserRole, setIsOpenModalSelectUserRole] =
    useState(false);
  const [fetching, setFetching] = useState(false);
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors, submitCount },
    handleSubmit,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      events: [],
      users: [],
    },
  });

  const watchUsers = watch("users");
  const currentColor = watch("color");

  const handleToggleModalSelectUserRole = () => {
    setIsOpenModalSelectUserRole((prev) => !prev);
  };

  const user = (id: string) => {
    const userSelected = options.find((i) => i.value === id)?.user;

    return userSelected;
  };

  const handleRemoveEvent = useCallback((id: string) => {
    setSelectedEvents((prev) => prev.filter((event) => event._id !== id));
  }, []);

  const handleClearEvents = () => {
    setSelectedEvents([]);
  };

  const handleToggleShowHexEditor = () => {
    setShowHexEditor((prev) => !prev);
  };

  const handleRemoveUser = (index: number) => {
    const options = watchUsers;

    options.splice(index, 1);

    setValue("users", options);
  };

  const debouncedFetchUsers = debounce((searchText) => {
    if (searchText) {
      fetchUsers(searchText);
    } else {
      setOptions([]);
    }
  }, 1000);

  const handleApplyUserRole = (role: TeamUsersRoleEnum) => {
    setIsOpenModalSelectUserRole(false);

    const options = watchUsers;

    if (options && options.length > 0) {
      const lastUserIndex = options.length - 1;
      const updatedOptions = [...options];

      updatedOptions[lastUserIndex] = {
        ...updatedOptions[lastUserIndex],
        role: role,
      };

      setValue("users", updatedOptions);
    }
  };

  useEffect(() => {
    debouncedFetchUsers(searchValue);
    return () => {
      debouncedFetchUsers.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    setValue(
      "events",
      selectedEvents.map((i) => ({
        _id: i._id,
      }))
    );
  }, [setValue, selectedEvents]);

  const fetchUsers = async (search: string) => {
    try {
      const response = await handleSearchMemberByEmail(search);
      const isValidEmail = await emailSchema.isValid(search);

      if (!response || response.length <= 0 || !isValidEmail) {
        setOptions([
          {
            value: search,
            label: (
              <AutocompleteOption>
                <strong>{search}</strong>
                <br />
                <span>Usuário não encontrado. Deseja enviar convite?</span>
              </AutocompleteOption>
            ),
            user: {
              email: search,
              name: "",
              _id: search,
            },
          },
        ]);

        return;
      }

      const results = response.map((user) => ({
        value: user._id,
        label: (
          <AutocompleteOption>
            <strong>{user.name}</strong>
            <br />
            <span>{user.email}</span>
          </AutocompleteOption>
        ),
        user,
      }));

      setOptions(results);
    } catch {
      setOptions([]);
      errorNotification(
        "Ocorreu um erro",
        "Erro ao buscar os usuários, tente novamente mais tarde."
      );
    }
  };

  const handleSelect = (data: any) => {
    const options = watchUsers;

    if (options.some((p) => p._id === (data as string))) {
      errorNotification("E-mail já adicionado anteriormente.");

      return;
    }

    options.push({
      _id: data,
    });

    setValue("users", options);
    handleToggleModalSelectUserRole();
  };

  const onSubmit = async (data: FormSchema) => {
    setFetching(true);

    try {
      await doCreateTeam({
        ...data,
        users: data.users.map((user) => ({
          role: user.role,
          _id: isEmailValid(user._id ?? "") ? undefined : user._id,
          email: isEmailValid(user._id ?? "") ? user._id : undefined,
        })),
      });

      mutate("/team");
      showModal();
      navigate("/teams");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          "Erro ao cadastrar o time",
          error?.response?.data?.message
        );

        return;
      }

      errorNotification(
        "Erro ao cadastrar o time",
        "Tente novamente mais tarde"
      );
    } finally {
      setFetching(false);
    }
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <Container onSubmit={handleSubmit(onSubmit)}>
        <div className="bread">
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Times", link: "/teams" },
              { name: "Novo time", link: "/teams/register" },
            ]}
          />
        </div>

        <Title>Novo time</Title>

        <Box>
          <BoxHeader>
            <h2>Detalhes do time</h2>

            <p>Preencha abaixo os campos para criar um novo time.</p>
          </BoxHeader>

          <InputForm
            {...register("name")}
            isFull
            icon={<RiTeamLine size={22} color={Colors.secondary40} />}
            label="Nome do time"
            placeholder="Insira o nome do time..."
            error={errors?.name}
          />

          <EventsContainer>
            <h3>Eventos vinculados ao time</h3>

            <FieldWrapper>
              <Controller
                name="events"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectEventFilter
                    title="Insira o nome do evento ou selecione um..."
                    label="Selecione os eventos"
                    sortKey="events"
                    onClearEvents={handleClearEvents}
                    showSelectedLenght={false}
                  />
                )}
              />

              {submitCount > 0 && selectedEvents.length <= 0 && (
                <span className="error">Você precisa informar um evento</span>
              )}
            </FieldWrapper>

            <ItemsSelectedContainer>
              {selectedEvents.map((event) => (
                <ItemSelected
                  startAdornment={
                    <RiCalendarCheckFill size={25} color={Colors.purple20} />
                  }
                  endAdornment={
                    <Button
                      type="ghost"
                      shape="circle"
                      icon={
                        <RiCloseCircleFill size={25} color={Colors.purple20} />
                      }
                      onClick={() => handleRemoveEvent(event._id)}
                    />
                  }
                  title={event.name}
                />
              ))}
            </ItemsSelectedContainer>
          </EventsContainer>

          <EventsContainer>
            <h3>Selecione os membros do time</h3>

            <FieldWrapper>
              <Autocomplete
                options={options}
                onSearch={setSearchValue}
                onSelect={handleSelect}
                placeholder="Insira nome ou e-mail do usuário..."
              >
                <InputWrapper>
                  <Input />
                </InputWrapper>
              </Autocomplete>

              {submitCount > 0 && watchUsers.length <= 0 && (
                <span className="error">Você precisa informar um membro</span>
              )}
            </FieldWrapper>

            <ItemsSelectedContainer>
              {watchUsers?.map((i, index) => (
                <ItemSelected
                  startAdornment={
                    <BiUserCircle size={25} color={Colors.purple20} />
                  }
                  endAdornment={
                    <Button
                      type="ghost"
                      shape="circle"
                      icon={
                        <RiCloseCircleFill size={25} color={Colors.purple20} />
                      }
                      onClick={() => handleRemoveUser(index)}
                    />
                  }
                  title={user(i?._id ?? "")?.name || ""}
                  description={user(i?._id ?? "")?.email ?? ""}
                  role={convertToTeamRole(i?.role ?? "")}
                />
              ))}
            </ItemsSelectedContainer>
          </EventsContainer>

          <Divider />

          <TeamColorWrapper>
            <h4>Cor do time</h4>

            <TeamColorContainer>
              <TeamColorButton
                color="#F55C5C"
                type="button"
                onClick={() => setValue("color", "#F55C5C")}
                className={
                  currentColor === "#F55C5C" ? "is-selected" : undefined
                }
              />
              <TeamColorButton
                color="#F2F55C"
                type="button"
                onClick={() => setValue("color", "#F2F55C")}
                className={
                  currentColor === "#F2F55C" ? "is-selected" : undefined
                }
              />
              <TeamColorButton
                color="#5CBEF5"
                type="button"
                onClick={() => setValue("color", "#5CBEF5")}
                className={
                  currentColor === "#5CBEF5" ? "is-selected" : undefined
                }
              />
              <TeamColorButton
                color="#C45CF5"
                type="button"
                onClick={() => setValue("color", "#C45CF5")}
                className={
                  currentColor === "#C45CF5" ? "is-selected" : undefined
                }
              />

              <TeamColorButton
                color={Colors.white}
                type="button"
                onClick={handleToggleShowHexEditor}
              >
                <RiAddFill size={25} color={Colors.secondary30} />
              </TeamColorButton>
            </TeamColorContainer>

            {showHexEditor && (
              <InputForm
                isFull
                label="# HEX"
                placeholder="Insira o valor hexadecimal da cor..."
                type="color"
                {...register("color")}
              />
            )}
            {errors.color?.message && (
              <span className="error">{errors.color.message}</span>
            )}
          </TeamColorWrapper>
        </Box>

        <ActionsContainer>
          <CancelButton>Cancelar</CancelButton>
          <ConfirmButton type="submit" disabled={fetching}>
            Salvar
          </ConfirmButton>
        </ActionsContainer>
      </Container>

      <Footer />

      <ModalFilterByEvent
        onSelectEvents={setSelectedEvents}
        onClearEvents={handleClearEvents}
      />

      <ModalSuccess
        title="Time criado com sucesso!"
        subtitle="Agora você já pode adicionar colaboradores em seu novo time."
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
      />

      <ModalStyled
        width={857}
        visible={isOpenModalSelectUserRole}
        onOk={handleToggleModalSelectUserRole}
        onCancel={handleToggleModalSelectUserRole}
        style={{ borderRadius: "20px" }}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ModalSelectUserRole onSelect={handleApplyUserRole} />
      </ModalStyled>
    </>
  );
}

export default TeamRegister;

import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { useFiltersContext } from "contexts/FiltersContext";

import { KeySort } from "constants/filters";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  Label,
  SelectStyledWithoutDrop,
} from "./styles";

interface SelectFilterModalProps {
  title: string;
  sortKey: KeySort;
  label?: string;
}

// pegar ingressos selecionado por parametro no caso o name ticket -> ingresso padrão 13
export function SelectTicketFilter({ title, label }: SelectFilterModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsOpenFilterModalTicket } = useFiltersContext();

  const tickets = searchParams.get("tickets") ?? "";

  const clearId = useCallback(() => {
    searchParams.delete("tickets");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  const handleOpenModal = () => {
    setIsOpenFilterModalTicket(true);
  };

  const ticketsLength = tickets?.split("%").length;

  return (
    <Container>
      {label ? <Label>{label}</Label> : null}
      {tickets === "" ? (
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: title }}
            open={false}
            onClick={handleOpenModal}
          />
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{ticketsLength} Ingressos selecionados</h3>

          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}

import { ReactNode } from "react";
import {
  Container,
  EndAdornmentContainer,
  ItemsSelectedContainer,
  TitleContainer,
} from "./styles";

export { ItemsSelectedContainer };

interface ItemSelectedProps {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  title: string;
  description?: string;
  role?: string;
}

function ItemSelected({
  startAdornment,
  endAdornment,
  title,
  description,
  role,
}: ItemSelectedProps) {
  return (
    <Container>
      {startAdornment}

      <TitleContainer>
        <span>
          {title} {role ? `- ${role}` : ""}
        </span>

        {description ? (
          <span className="description">{description}</span>
        ) : null}
      </TitleContainer>

      <EndAdornmentContainer>{endAdornment}</EndAdornmentContainer>
    </Container>
  );
}

export default ItemSelected;

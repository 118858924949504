import { authClient } from "services/authClient";
import {
  CreateTeamDTO,
  CreateTeamMemberDTO,
  ListTeam,
  UserResponse,
  TeamDetailResponse,
  EditTeamDTO,
  ListTeamDto,
} from "./types";

export type SearchUserResponse = Array<UserResponse>;

export async function doCreateTeam(body: CreateTeamDTO) {
  return authClient.post("/team", body);
}

export async function handleSearchMemberByEmail(
  email: string
): Promise<SearchUserResponse> {
  const { data } = await authClient.get<SearchUserResponse>(
    `/team/user/invite/search?email=${email}`
  );

  return data;
}

export async function doCreateTeamMember({
  email,
  role,
  teamId,
}: CreateTeamMemberDTO) {
  const { data } = await authClient.post(`/team/${teamId}/user`, {
    email,
    role,
  });

  return data;
}

export async function getTeams({ id }: ListTeamDto): Promise<ListTeam> {
  const { data } = await authClient.get<ListTeam>(`/team?id=${id}`);

  return data;
}

export async function getTeamDetail(id: string): Promise<TeamDetailResponse> {
  const { data } = await authClient.get<TeamDetailResponse>(`/team/${id}`);

  return data;
}

export async function editTeam(id: string, body: EditTeamDTO) {
  const { data } = await authClient.patch(`/team/${id}`, body);

  return data;
}

export async function deleteTeam(id: string) {
  const { data } = await authClient.delete(`/team/${id}`);

  return data;
}

import CryptoJS from "crypto-js";

export function useSecureLocalStorage() {
  const setItem = (name: string, data: any) => {
    const key = `${process.env.REACT_APP_STORAGE_VERSION}-${name}`;

    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY ?? ""
    ).toString();

    localStorage.setItem(key, encrypted);
  };

  const getItem = (name: string) => {
    const key = `${process.env.REACT_APP_STORAGE_VERSION}-${name}`;
    const encrypted = localStorage.getItem(key);

    if (!encrypted) {
      return;
    }

    const decrypted = CryptoJS.AES.decrypt(
      encrypted,
      process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY ?? ""
    ).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  };

  return { setItem, getItem };
}

import { Colors } from "constants/colors";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  padding: 44px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.87rem;

    color: ${Colors.secondary100};
    font-family: "Poppins";
    margin-bottom: 0px;
    @media (max-width: 800px) {
      font-size: 20px;
    }
  }

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;

    margin-top: 0.93rem;

    color: ${Colors.black70};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  .divider {
    height: 42px;
    width: 2px;
    background-color: ${Colors.secondary20};
  }

  > span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.625rem;
    color: ${Colors.secondary70};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Option = styled(Link)`
  border: none;
  padding: 20px 0;
  border-bottom: 1px solid #e1e1e1;
  background: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  column-gap: 12px;

  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  font-family: "Open Sans";

  &.negative {
    color: ${Colors.error};
  }

  &.positive {
    color: ${Colors.success};
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${Colors.white20};
    border-radius: 50%;

    width: 34px;
    height: 34px;
  }
`;

export const OptionButton = styled.button`
  border: none;
  padding: 20px 0;
  border-bottom: 1px solid #e1e1e1;
  background: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  column-gap: 12px;

  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  font-family: "Open Sans";

  &.negative {
    color: ${Colors.error};
  }

  &.positive {
    color: ${Colors.success};
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${Colors.white20};
    border-radius: 50%;

    width: 34px;
    height: 34px;
  }
`;

export const ButtonBase = css`
  cursor: pointer;

  max-height: 60px;
  width: 100%;
  padding: 14px 20px;

  border-radius: 14px;

  font-family: "Cairo";
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.2;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;

  @media (max-width: 1024px) {
    margin-top: auto;
    flex-direction: column-reverse;
    row-gap: 20px;
  }
`;

export const ConfirmButton = styled.button`
  ${ButtonBase}

  color: ${Colors.white};
  box-shadow: 0px 15px 30px 0px #d4005026;
  background-color: ${Colors.primary100};

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const CancelButton = styled.button`
  ${ButtonBase}

  color: ${Colors.primary100};
  background-color: ${Colors.primary10};
`;

import { forwardRef, ForwardRefRenderFunction } from "react";

import { FiMapPin } from "react-icons/fi";
import { RiCalendar2Line } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  BackgroundTicket,
  Container,
  ContainerContentModalTicket,
  DivBorder,
  DivLogo,
} from "./styles";

import event_ticket from "assets/event-ticket.svg";
import QRCode from "react-qr-code";

export interface TicketPrintType {
  event: {
    name?: string;
    thumb?: string;
    photo?: string;
    address_city?: string;
    address_district?: string;
    address_name?: string;
    address_number?: string;
    address_state?: string;
    address_street?: string;
    type_event?: number;
    begin_date?: string;
    begin_hour?: string;
  };
  event_ticket_id: {
    _id?: string;
    name?: string;
    description?: string;
  };
  purchase_id?: string;
  holder_name?: string;
}
interface TicketForPrintComponentProps {
  selectedTicket?: TicketPrintType | null;
}

const TicketForPrintComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  TicketForPrintComponentProps
> = ({ selectedTicket, ...rest }, ref) => {
  return (
    <Container
      ref={ref}
      {...rest}
      id="print-ticket"
      style={{ position: "absolute", top: "-9999px" }}
    >
      <BackgroundTicket>
        <div className="img"></div>

        <div className="content">
          <h2 className="title">{selectedTicket?.event?.name}</h2>

          <div className="text">
            <FiMapPin size={18} color={Colors.white} />

            <h4>
              {selectedTicket?.event?.address_name}{" "}
              {selectedTicket?.event?.address_street
                ? `| ${selectedTicket?.event?.address_street}`
                : ""}
            </h4>
          </div>

          <div className="text-last">
            <RiCalendar2Line size={18} color={Colors.white} />
            <p>
              {selectedTicket?.event?.begin_date},{" "}
              {selectedTicket?.event?.begin_hour}
            </p>
          </div>
        </div>
      </BackgroundTicket>

      <DivBorder>
        <ContainerContentModalTicket>
          <div className="data">
            <h3 className="data">Dados do ingresso 1</h3>

            <div className="table">
              <h5>Nome do comprador</h5>
              <p>{selectedTicket?.holder_name}</p>
            </div>

            <div className="table">
              <h5>Ingresso</h5>
              <p>{selectedTicket?.event_ticket_id?.name}</p>
            </div>

            <div className="table">
              <h5>Código da compra</h5>
              <p>{selectedTicket?.event_ticket_id?._id}</p>
            </div>
          </div>

          <div className="qr">
            <QRCode
              size={150}
              value={selectedTicket?.event_ticket_id?._id || ""}
            />

            <h1>{selectedTicket?.event_ticket_id?._id}</h1>
          </div>
        </ContainerContentModalTicket>

        <DivLogo>
          <div className="all-content">
            <img className="eventx-logo-ticket" src={event_ticket} alt="Logo" />
            <span>A VIDA É UM EVENTO!</span>
          </div>
        </DivLogo>
      </DivBorder>
    </Container>
  );
};

export const TicketForPrint = forwardRef(TicketForPrintComponent);

import { useEffect, useState, useCallback, useRef } from "react";

import { Spin, Checkbox, Drawer, Select } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { RiCalendar2Line, RiQuestionLine } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { BsFillEyeFill } from "react-icons/bs";

import { useTabsTicketsAndPurchases } from "contexts/TabsTicketsAndPurchasesContext";

import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";
import { date } from "utils/masks";

import { TicketType } from "types";

import html2Canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  Container,
  ContainerItems,
  ContainerAllItemsMobile,
  CardTicket,
  ListAccordion,
  CardAccordion,
  DataDropDown,
  ContainerBorder,
  ContainerButtons,
  ButtonVisualizationTicket,
  ButtonDownload,
  ContainerHeader,
  ContainerFilters,
  ContainerDrawer,
  InputContainer,
  Center,
  DivEmpty,
} from "./styles";

import Popup from "containers/Popup";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { TicketForPrint, TicketPrintType } from "components/TicketForPrint";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { SidebarProfile } from "components/SidebarProfile";
import { Pagination } from "components/Pagination";
import { Title } from "components/Title";

import ticket_profile from "assets/ticket-profile.svg";

import TabsTicketsAndPurchases from "../TabTicketAndPurchase";
import { GridSidebar } from "styles/pages/Sidebar";
import { buildMicroRoute } from "utils/urlBuilder";
import { useSearchTicket } from "hooks/useSearchTicket";
import { ModalTicketDetail } from "components/ModalTicketDetail";
import { useDisclosure } from "hooks/useDisclosure";
import { createPortal } from "react-dom";

const { Option } = Select;

const metaTags = {
  title: `Meus ingressos - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function MyTickets() {
  const {
    selectedTicketMobile,
    selectedPurchaseMobile,
    closeTicket,
    closePurchase,
  } = useTabsTicketsAndPurchases();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const [visibleMobile, setVisibleMobile] = useState(false);

  const [selectedTicket, setSelectedTicket] = useState<TicketPrintType | null>(
    null
  );

  const componentRef = useRef(null);

  const { showModal, handleCancel, handleOk, isModalVisible } = useDisclosure();

  const { data, onChange, handleOpenDropTicketEventMobile, page } =
    useSearchTicket();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const onClose = () => {
    setVisible(false);
  };

  const onCloseMobile = () => {
    setVisibleMobile(false);
  };

  const handleKeyUpDate = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      date(e);
    },
    []
  );

  const showModalTicket = (ticket: TicketPrintType) => {
    setSelectedTicket(ticket);
    showModal();
  };

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const downloadPDF = async (ticket: TicketPrintType) => {
    setSelectedTicket(ticket);

    await sleep(1000);

    const input = componentRef.current;

    if (input) {
      html2Canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;
        pdf.addImage(
          imgData,
          "JPEG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );

        pdf.save(selectedTicket?.event_ticket_id?.name ?? "Ingresso");
      });
    }
  };

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {});
    });

    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      {!selectedTicketMobile && !selectedPurchaseMobile ? (
        <Header isProfile metaTags={metaTags} />
      ) : null}

      {selectedTicketMobile && !selectedPurchaseMobile ? (
        <Header isProfile customBack={closeTicket} metaTags={metaTags} />
      ) : null}

      {selectedPurchaseMobile && !selectedTicketMobile ? (
        <Header isProfile customBack={closePurchase} metaTags={metaTags} />
      ) : null}

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/" },
              ]}
            />
          </div>

          <Title />

          <GridSidebar>
            <SidebarProfile />

            <CardTicket>
              <ContainerHeader>
                <h2>Meus ingressos</h2>

                <ContainerFilters></ContainerFilters>
              </ContainerHeader>

              <ListAccordion>
                {!data ? (
                  <Center>
                    <Spin spinning indicator={antIcon} />
                  </Center>
                ) : null}

                {data?.results?.length === 0 ? (
                  <DivEmpty>
                    <h2 className="empty">Não há nada por aqui :(</h2>

                    <p className="empty">Não há Ingressos</p>
                  </DivEmpty>
                ) : null}

                {data?.results &&
                  data?.results.length > 0 &&
                  data?.results?.map((ticket: TicketType) => {
                    if (ticket.event === null) {
                      return null;
                    }

                    return (
                      <div key={ticket._id}>
                        <CardAccordion
                          active={ticket?.isOpen}
                          onClick={() =>
                            handleOpenDropTicketEventMobile(ticket._id)
                          }
                        >
                          <div className="content">
                            <div className="image">
                              <img
                                className="ticket"
                                src={ticket_profile}
                                alt="Logo"
                                width={25}
                                height={22.5}
                              />
                            </div>

                            <div className="texts">
                              <h2>
                                {ticket?.event
                                  ? ticket?.event?.name
                                  : "Não Informado"}
                              </h2>

                              <p>
                                {ticket?.event_ticket_id?.name} {"\u00B7"}{" "}
                                {ticket.formattedDate}
                              </p>
                            </div>
                          </div>

                          {ticket?.isOpen ? (
                            <GoChevronDown
                              size={22}
                              color={Colors.secondary50}
                            />
                          ) : (
                            <GoChevronUp size={22} color={Colors.secondary50} />
                          )}
                        </CardAccordion>

                        {ticket.isOpen ? (
                          <DataDropDown>
                            <ContainerBorder>
                              <div className="all">
                                <div className="content">
                                  <div className="column">
                                    <h2>Nome do titular</h2>

                                    <p>{ticket.holder_name}</p>
                                  </div>
                                  <div className="column">
                                    <h2>E-mail do titular</h2>

                                    <p>{ticket.holder_email}</p>
                                  </div>
                                  <div className="column">
                                    <h2>Código da compra</h2>

                                    <p>{ticket?.purchase_id}</p>
                                  </div>
                                </div>
                                <div className="container-image">
                                  <img
                                    className="ticket"
                                    src={ticket?.event?.thumb}
                                    alt="Logo"
                                    width="195px"
                                    height="99px"
                                  />
                                </div>
                              </div>
                            </ContainerBorder>

                            <ContainerButtons>
                              <ButtonVisualizationTicket
                                onClick={() =>
                                  showModalTicket({
                                    event: {
                                      name: ticket?.event?.name,
                                      address_city: ticket?.event?.address_city,
                                      address_district:
                                        ticket?.event?.address_district,
                                      address_name: ticket?.event?.address_name,
                                      address_number: String(
                                        ticket?.event?.address_number
                                      ),
                                      address_state:
                                        ticket?.event?.address_state,
                                      address_street:
                                        ticket?.event?.address_street,
                                      begin_date: ticket?.event?.begin_date,
                                      begin_hour: ticket?.event?.begin_hour,
                                      thumb: ticket?.event?.thumb,
                                      type_event: ticket?.event?.type_event,
                                    },
                                    event_ticket_id: ticket?.event_ticket_id,
                                    holder_name: ticket?.holder_name,
                                    purchase_id: ticket?.purchase_id,
                                  })
                                }
                              >
                                <BsFillEyeFill size={18} color={Colors.white} />
                                <span>Visualizar ingresso</span>
                              </ButtonVisualizationTicket>

                              <ButtonDownload
                                onClick={() => {
                                  downloadPDF({
                                    event: {
                                      name: ticket?.event?.name,
                                      address_city: ticket?.event?.address_city,
                                      address_district:
                                        ticket?.event?.address_district,
                                      address_name: ticket?.event?.address_name,
                                      address_number: String(
                                        ticket?.event?.address_number
                                      ),
                                      address_state:
                                        ticket?.event?.address_state,
                                      address_street:
                                        ticket?.event?.address_street,
                                      begin_date: ticket?.event?.begin_date,
                                      begin_hour: ticket?.event?.begin_hour,
                                      thumb: ticket?.event?.thumb,
                                      type_event: ticket?.event?.type_event,
                                    },
                                    event_ticket_id: ticket?.event_ticket_id,
                                    holder_name: ticket?.holder_name,
                                    purchase_id: ticket?.purchase_id,
                                  });
                                }}
                              >
                                <IoMdDownload
                                  size={18}
                                  color={Colors.primary100}
                                />
                                <span> Fazer download</span>
                              </ButtonDownload>
                            </ContainerButtons>
                          </DataDropDown>
                        ) : null}
                      </div>
                    );
                  })}
              </ListAccordion>

              <Pagination
                title="ingressos"
                totalCountOfRegisters={data?.count}
                currentPage={page}
                onPageChange={onChange}
              />
            </CardTicket>
          </GridSidebar>

          <ContainerAllItemsMobile>
            {!selectedPurchaseMobile ? (
              <div className="header">
                <h1> Meus ingressos</h1>

                <a
                  href={buildMicroRoute("/faq")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiQuestionLine size={24} color={Colors.gray30} />
                </a>
              </div>
            ) : null}

            <TabsTicketsAndPurchases isTickets />
          </ContainerAllItemsMobile>
        </ContainerItems>

        <Drawer
          title="Filtros"
          className="custom-drawer"
          width={420}
          placement="right"
          onClose={onClose}
          visible={false}
        >
          <ContainerDrawer>
            <div className="data-drawer">
              <div className="data">
                <h1>Data</h1>

                <InputContainer>
                  <div>
                    <RiCalendar2Line size={18} color={Colors.secondary40} />
                  </div>
                  <input
                    type="text"
                    placeholder="Selecione uma data"
                    onKeyUp={handleKeyUpDate}
                  />
                </InputContainer>
              </div>
            </div>

            <div className="next-drawer">
              <div className="next">
                <h1>Categorias</h1>

                <div>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Próximos eventos
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Eventos que já aconteceram
                  </Checkbox>
                </div>
              </div>
            </div>

            <div className="select-drawer">
              <div className="select">
                <h1>Tipo de ingresso</h1>

                <div>
                  <Select
                    defaultValue="Selecione uma opção"
                    style={{ width: "100%" }}
                    onChange={() => {}}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </div>
              </div>
            </div>

            <div className="buttons-drawer">
              <button className="first">Limpar</button>

              <button className="second">Aplicar</button>
            </div>
          </ContainerDrawer>
        </Drawer>

        <Drawer
          title="Filtros"
          className="custom-drawer"
          width={278}
          drawerStyle={{ color: Colors.secondary100 }}
          headerStyle={{
            paddingLeft: "14px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
            color: Colors.secondary100,
            fontWeight: "bold",
          }}
          closable={false}
          bodyStyle={{ padding: 0 }}
          placement="right"
          onClose={onCloseMobile}
          visible={visibleMobile}
        >
          <ContainerDrawer>
            <div className="data-drawer">
              <div className="data">
                <h1>Data</h1>

                <InputContainer>
                  <div>
                    <RiCalendar2Line size={18} color={Colors.secondary40} />
                  </div>
                  <input
                    type="text"
                    placeholder="Selecione uma data"
                    onKeyUp={handleKeyUpDate}
                  />
                </InputContainer>
              </div>
            </div>

            <div className="next-drawer">
              <div className="next">
                <h1>Categorias</h1>

                <div>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Próximos eventos
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Eventos que já aconteceram
                  </Checkbox>
                </div>
              </div>
            </div>

            <div className="select-drawer">
              <div className="select">
                <h1>Tipo de ingresso</h1>

                <div>
                  <Select
                    defaultValue="Selecione uma opção"
                    style={{ width: "100%" }}
                    onChange={() => {}}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </div>
              </div>
            </div>

            <div className="buttons-drawer">
              <button className="first">Limpar</button>

              <button className="second">Aplicar</button>
            </div>
          </ContainerDrawer>
        </Drawer>

        {selectedTicket ? (
          <ModalTicketDetail
            selectedTicket={selectedTicket}
            componentRef={componentRef}
            handleCancel={handleCancel}
            handleOk={handleOk}
            isModalVisible={isModalVisible}
          />
        ) : null}

        {createPortal(
          <>
            {selectedTicket ? (
              <TicketForPrint
                ref={componentRef}
                selectedTicket={selectedTicket}
              />
            ) : null}
          </>,
          document.body
        )}
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}

import { AutoComplete } from "antd";
import { Colors } from "constants/colors";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Modal } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const Title = styled.h1`
  margin-top: 54px;

  font-size: 2.5rem;
  line-height: 4rem;
  font-weight: 700;
  color: ${Colors.black100};

  @media (max-width: 1024px) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0;
  }
`;

export const Container = styled.form`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  padding: calc(100px + 70px) 38px;
  background: ${Colors.white};

  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 92px 28px;

    div.bread {
      display: none;
    }
  }
`;

export const Box = styled.div`
  margin-top: 50px;
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 40px;

  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  border-radius: 20px;

  padding: 64px 135px;

  @media (max-width: 1024px) {
    border: none;
    padding: 0;
    row-gap: 24px;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 40px;

  border-bottom: 1px solid ${Colors.black10};

  > h2 {
    font-family: "Poppins";
    font-size: 1.625rem;
    line-height: 2.6rem;
    font-weight: 600;
    color: ${Colors.secondary100};
  }

  > p {
    font-family: "Open Sans";
    font-size: 1.375rem;
    line-height: 2.2rem;
    color: ${Colors.black70};
    margin: 0;
  }

  @media (max-width: 1024px) {
    > h2 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    > p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

export const ActionsContainer = styled.div`
  margin-top: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    row-gap: 16px;
  }
`;

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  > h3 {
    font-family: "Poppins";
    font-weight: 500;
    color: ${Colors.secondary100};
    font-size: 1.375rem;
    line-height: 2.2rem;
  }

  @media (max-width: 1024px) {
    row-gap: 16px;

    > h3 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

export const ButtonBase = css`
  cursor: pointer;

  max-width: 390px;
  width: 100%;
  padding: 20px 0;

  border-radius: 14px;

  font-family: "Cairo";
  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.2;
  }
`;

export const ConfirmButton = styled.button`
  ${ButtonBase}

  color: ${Colors.white};
  box-shadow: 0px 15px 30px 0px #d4005026;
  background-color: ${Colors.primary100};
`;

export const CancelButton = styled.button`
  ${ButtonBase}

  color: ${Colors.primary100};
  background-color: ${Colors.primary10};
`;

export const EmptyCollaboratorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 28px;

  > h4 {
    font-family: "Poppins";
    font-size: 1.375rem;
    line-height: 2.2rem;
    font-weight: 500;
    color: ${Colors.secondary100};
  }

  @media (max-width: 1024px) {
    row-gap: 16px;

    > h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

export const NewCollaboratorButton = styled(Link)`
  ${ButtonBase}

  max-width: 535px;
  padding: 14px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  color: ${Colors.primary100};
  background-color: ${Colors.primary10};

  svg {
    color: ${Colors.primary100};
  }

  &:hover {
    opacity: 0.8;
    color: ${Colors.primary100};
  }

  @media (max-width: 1024px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const TeamColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  > h4 {
    font-family: "Poppins";
    font-size: 1.375rem;
    line-height: 2.2rem;
    font-weight: 500;
    color: ${Colors.secondary100};
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;

    margin-top: -20px;
  }
`;

export const TeamColorContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const TeamColorButton = styled.button<{ color: string }>`
  height: 62px;
  width: 62px;
  border-radius: 10px;
  border: 1px solid ${Colors.border20};

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ color }) => color};

  &:hover {
    opacity: 0.8;
    border-color: ${Colors.secondary70};
    transition: border-color 0.2s linear;

    cursor: pointer;
  }

  &.is-selected {
    border: 3px solid ${Colors.secondary70};
  }
`;

export const Autocomplete = styled(AutoComplete)`
  height: 60px;

  .ant-select-selector {
    display: flex;
    justify-content: center;
  }

  .ant-select-selection-placeholder {
    top: 14px;
    left: 14px !important;
  }
`;

export const InputWrapper = styled.div`
  padding: 14px 20px;
  border: 2px solid ${Colors.secondary20};
  border-radius: 8px;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;

  ::placeholder {
    font-family: "Poppins";
    font-size: 1.375rem;
    line-height: 2.2rem;
    font-weight: 500;
    color: ${Colors.secondary100};
  }
`;

export const AutocompleteOption = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  > strong {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.secondary100};
  }

  > span {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: ${Colors.secondary100};
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.areCookiesEqual = exports.hasSameProperties = exports.createCookie = exports.isBrowser = void 0;
/**
 * Tells whether we are in a browser or server.
 */
function isBrowser() {
    return typeof window !== 'undefined';
}
exports.isBrowser = isBrowser;
/**
 * Create an instance of the Cookie interface
 */
function createCookie(name, value, options) {
    var sameSite = options.sameSite;
    if (sameSite === true) {
        sameSite = 'strict';
    }
    if (sameSite === undefined || sameSite === false) {
        sameSite = 'lax';
    }
    var cookieToSet = __assign(__assign({}, options), { sameSite: sameSite });
    delete cookieToSet.encode;
    return __assign({ name: name, value: value }, cookieToSet);
}
exports.createCookie = createCookie;
/**
 * Tells whether given objects have the same properties.
 */
function hasSameProperties(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length !== bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}
exports.hasSameProperties = hasSameProperties;
/**
 * Compare the cookie and return true if the cookies have equivalent
 * options and the cookies would be overwritten in the browser storage.
 *
 * @param a first Cookie for comparison
 * @param b second Cookie for comparison
 */
function areCookiesEqual(a, b) {
    var sameSiteSame = a.sameSite === b.sameSite;
    if (typeof a.sameSite === 'string' && typeof b.sameSite === 'string') {
        sameSiteSame = a.sameSite.toLowerCase() === b.sameSite.toLowerCase();
    }
    return (hasSameProperties(__assign(__assign({}, a), { sameSite: undefined }), __assign(__assign({}, b), { sameSite: undefined })) && sameSiteSame);
}
exports.areCookiesEqual = areCookiesEqual;
/* Functions */

import { FaFileImport } from "react-icons/fa";
import { Container } from "./styles";

interface ImportPurchasesButtonProps {
  onClick: () => void;
}

function ImportPurchasesButton({ onClick }: ImportPurchasesButtonProps) {
  return (
    <Container type="button" onClick={onClick}>
      <FaFileImport />
      <span>Importar vendas</span>
    </Container>
  );
}

export default ImportPurchasesButton;

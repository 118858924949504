import { MdSend } from "react-icons/md";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiAddLine } from "react-icons/ri";
import { Colors } from "constants/colors";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import {
  Box,
  BoxHeader,
  Container,
  Title,
  AddCollaborator,
  CollaboratorsWrapper,
  ActionsContainer,
  CancelButton,
  ConfirmButton,
  BoxDivider,
} from "./styles";
import AccordionNewCollaborator from "./components/AccordionNewCollaborator";
import { FormSchema, formSchema } from "./misc";
import { useNavigate, useParams } from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import { doCreateTeamMember } from "services/teams";
import axios from "axios";
import { useEffect, useState } from "react";

const metaTags = {
  title: `Novo colaborador - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

type Params = {
  id: string;
};

function CollaboratorRegister() {
  const navigate = useNavigate();
  const { id: teamId } = useParams<Params>();
  const [fetching, setFetching] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      collaborators: [
        {
          email: "",
          name: "",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "collaborators",
  });

  const collaborators = watch("collaborators");

  const handleAddCollaborator = () => {
    append({ email: undefined, name: undefined, role: undefined });
  };

  const onSubmit = async (data: FormSchema) => {
    try {
      if (!teamId) return;

      setFetching(true);

      data.collaborators.forEach(async (member, index) => {
        try {
          await doCreateTeamMember({
            email: member.email,
            role: member.role,
            teamId,
          });

          const currentIndex = data.collaborators.length - 1;

          if (currentIndex === index) {
            successNotification(
              "Sucesso.",
              "Convite(s) enviado(s) com sucesso."
            );
            setFetching(false);
            navigate("/teams");
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            errorNotification(
              `Erro ao enviar convite (${member.email})`,
              error?.response?.data?.message
            );

            setFetching(false);

            return;
          }
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          "Erro ao cadastrar o time",
          error?.response?.data?.message
        );

        return;
      }

      errorNotification(
        "Erro ao cadastrar o time",
        "Tente novamente mais tarde"
      );
    }
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <Container onSubmit={handleSubmit(onSubmit)}>
        <div className="bread">
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Times", link: "/teams" },
              { name: "Colaborador", link: "/collaborators/new" },
            ]}
          />
        </div>

        <Title>Novo colaborador</Title>

        <Box>
          <BoxHeader>
            <h2>Cadastre um novo colaborador ao seu time</h2>

            <p>
              Envie convites para novos colaboradores para adicioná-los em seu
              time
            </p>
          </BoxHeader>

          <CollaboratorsWrapper>
            {fields.map((field, index) => (
              <AccordionNewCollaborator
                collaborator={{
                  name: watch(`collaborators.${index}.name`),
                  email: watch(`collaborators.${index}.email`),
                  role: watch(`collaborators.${index}.role`),
                }}
                key={field.id}
                index={index}
                register={register}
                control={control}
                errors={errors ?? undefined}
                defaultOpen={index === 0}
                onRemove={remove}
              />
            ))}
          </CollaboratorsWrapper>

          <AddCollaborator onClick={handleAddCollaborator} type="button">
            <RiAddLine />
            Adicionar colaborador
          </AddCollaborator>
        </Box>

        <BoxDivider />

        <ActionsContainer>
          <CancelButton>Voltar</CancelButton>
          <ConfirmButton
            type="submit"
            disabled={fetching || collaborators.length <= 0}
          >
            <MdSend color={Colors.white} size={28} />
            Enviar convite
          </ConfirmButton>
        </ActionsContainer>
      </Container>
    </>
  );
}

export default CollaboratorRegister;

export function applyOpacityToHexColor(hexColor: string): string {
  if (hexColor[0] !== "#") return "#FFF";

  const hex = hexColor.slice(1);

  const alpha = Math.round(0.1 * 255)
    .toString(16)
    .padStart(2, "0");

  return `#${hex}${alpha}`;
}

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.destroyCookie = exports.setCookie = exports.parseCookies = void 0;
var cookie = require("cookie");
var setCookieParser = require("set-cookie-parser");
var utils_1 = require("./utils");
/**
 * Parses cookies.
 *
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param options Options that we pass down to `cookie` library.
 */
function parseCookies(ctx, options) {
    var _a, _b;
    if ((_b = (_a = ctx === null || ctx === void 0 ? void 0 : ctx.req) === null || _a === void 0 ? void 0 : _a.headers) === null || _b === void 0 ? void 0 : _b.cookie) {
        return cookie.parse(ctx.req.headers.cookie, options);
    }
    if (utils_1.isBrowser()) {
        return cookie.parse(document.cookie, options);
    }
    return {};
}
exports.parseCookies = parseCookies;
/**
 * Sets a cookie.
 *
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param name The name of your cookie.
 * @param value The value of your cookie.
 * @param options Options that we pass down to `cookie` library.
 */
function setCookie(ctx, name, value, options) {
    var _a, _b;
    if (options === void 0) { options = {}; }
    // SSR
    if (((_a = ctx === null || ctx === void 0 ? void 0 : ctx.res) === null || _a === void 0 ? void 0 : _a.getHeader) && ctx.res.setHeader) {
        // Check if response has finished and warn about it.
        if ((_b = ctx === null || ctx === void 0 ? void 0 : ctx.res) === null || _b === void 0 ? void 0 : _b.finished) {
            console.warn("Not setting \"" + name + "\" cookie. Response has finished.");
            console.warn("You should set cookie before res.send()");
            return {};
        }
        /**
         * Load existing cookies from the header and parse them.
         */
        var cookies = ctx.res.getHeader('Set-Cookie') || [];
        if (typeof cookies === 'string')
            cookies = [cookies];
        if (typeof cookies === 'number')
            cookies = [];
        /**
         * Parse cookies but ignore values - we've already encoded
         * them in the previous call.
         */
        var parsedCookies = setCookieParser.parse(cookies, {
            decodeValues: false,
        });
        /**
         * We create the new cookie and make sure that none of
         * the existing cookies match it.
         */
        var newCookie_1 = utils_1.createCookie(name, value, options);
        var cookiesToSet_1 = [];
        parsedCookies.forEach(function (parsedCookie) {
            if (!utils_1.areCookiesEqual(parsedCookie, newCookie_1)) {
                /**
                 * We serialize the cookie back to the original format
                 * if it isn't the same as the new one.
                 */
                var serializedCookie = cookie.serialize(parsedCookie.name, parsedCookie.value, __assign({ 
                    // we prevent reencoding by default, but you might override it
                    encode: function (val) { return val; } }, parsedCookie));
                cookiesToSet_1.push(serializedCookie);
            }
        });
        cookiesToSet_1.push(cookie.serialize(name, value, options));
        // Update the header.
        ctx.res.setHeader('Set-Cookie', cookiesToSet_1);
    }
    // Browser
    if (utils_1.isBrowser()) {
        if (options && options.httpOnly) {
            throw new Error('Can not set a httpOnly cookie in the browser.');
        }
        document.cookie = cookie.serialize(name, value, options);
    }
    return {};
}
exports.setCookie = setCookie;
/**
 * Destroys a cookie with a particular name.
 *
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param name Cookie name.
 * @param options Options that we pass down to `cookie` library.
 */
function destroyCookie(ctx, name, options) {
    /**
     * We forward the request destroy to setCookie function
     * as it is the same function with modified maxAge value.
     */
    return setCookie(ctx, name, '', __assign(__assign({}, (options || {})), { maxAge: -1 }));
}
exports.destroyCookie = destroyCookie;
/* Utility Exports */
exports.default = {
    set: setCookie,
    get: parseCookies,
    destroy: destroyCookie,
};

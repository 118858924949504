import { useCallback, useEffect, useRef, useState } from "react";

import {
  EditingBoardContent,
  HorizontalLine,
  ImagePreview,
  PhotoPreviewContainer,
  PictureUploadButton,
  ButtonSecondary,
  ButtonPrimary,
  ContainerButtons,
} from "../../styles";
import { RiCalendarCheckFill, RiAddLine, RiUserLine } from "react-icons/ri";

import { InputForm } from "components/Form/InputForm";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { CreateInformationEventFormData, Event } from "types";
import { Colors } from "constants/colors";
import {
  EditEventPhotoService,
  EditEventService,
  doCheckEventNicknameAvailability,
} from "services/Hook/Event";
import { ModalSuccess } from "components/ModalSuccess";
import { useDisclosure } from "hooks/useDisclosure";

interface BasicInfoProps {
  activeStep: number;
  data: Event;
}

const createInformationEventFormSchema = yup.object().shape({
  name: yup.string().required("Nome do evento obrigatório"),
  image: yup.string().notRequired(),
  nickname: yup.string().required("O Link é obrigatório"),
});

export function BasicInfo({ activeStep, data }: BasicInfoProps) {
  const [file, setFile] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<CreateInformationEventFormData>({
    resolver: yupResolver(createInformationEventFormSchema),
  });

  const fileInputRef = useRef(null);

  const { showModal, handleOk, handleCancel, isModalVisible } = useDisclosure();

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileSelected = e.target.files[0];

      setFile(fileSelected);
    }
  };

  const isValidNickname = useCallback(async (nickname: string): Promise<boolean> => {
    try {
      await doCheckEventNicknameAvailability(
        nickname
      );

      return false;
    } catch {
      return true;
    }
  }, []);

  const handleEditBasicInfo: SubmitHandler<
    CreateInformationEventFormData
  > = async ({ name, nickname }) => {
    try {
      if (data?.nickname !== nickname) {
        const isAvailableNickname = await isValidNickname(
          nickname
        );

        if (!isAvailableNickname) {
          setError("nickname", {
            type: "required",
            message: "Nickname já existe",
          });

          return;
        }
      }

      const dataEvent = {
        ...data,
        name,
        nickname,
      };

      await EditEventService({
        id: data?._id,
        dataEvent,
      });

      if (data?._id && file) {
        await EditEventPhotoService({ eventId: data?._id, file });
      }

      showModal();
    } catch (err) {}
  };

  useEffect(() => {
    reset({
      name: data?.name,
    });
  }, [data, reset]);

  return (
    <EditingBoardContent onSubmit={handleSubmit(handleEditBasicInfo)}>
      <h3>{activeStep}. Informações básicas</h3>
      <p>Defina o nome do seu evento e a imagem de capa do mesmo</p>
      <HorizontalLine />
      <div>
        <h4>Qual o nome do seu evento?</h4>

        <InputForm
          isFull
          {...register("name")}
          error={errors.name}
          icon={<RiCalendarCheckFill size={17} color={Colors.secondary40} />}
          label="Nome do evento"
        />
      </div>
      <HorizontalLine />
      <h4>Adicione uma imagem de capa</h4>
      <small>
        <em>
          <strong>Especificações:</strong> arquivo do tipo <strong>PNG</strong>{" "}
          ou <strong>JPEG</strong>, com dimensão recomendada de{" "}
          <strong>1580x660</strong> px e tamanho máximo de <strong>4MB</strong>.
        </em>
      </small>
      <div>
        <input
          id="event-photo"
          type="file"
          onChange={onSelectFile}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <label htmlFor="event-photo" style={{ zIndex: 10 }}>
          <PictureUploadButton>
            <RiAddLine size={22} />
            <span>Adicionar imagem</span>
          </PictureUploadButton>
        </label>
      </div>
      <PhotoPreviewContainer>
        {file ? <ImagePreview photo={URL.createObjectURL(file)} /> : null}

        {!file ? (
          <ImagePreview photo={data?.photo}>
            {data?.photo ? null : "Sem imagem carregada"}
          </ImagePreview>
        ) : null}
      </PhotoPreviewContainer>

      <HorizontalLine marginY={0} />

      <InputForm
        isFull
        {...register("nickname")}
        mask="especial"
        hasPrefix
        prefix="eventx.com.br/"
        icon={<RiUserLine size={17} color={Colors.secondary40} />}
        error={errors.nickname}
        label="Link Eventx"
        defaultValue={data?.nickname}
      />

      <ContainerButtons>
        <ButtonSecondary type="button" onClick={() => {}}>
          <span>Cancelar</span>
        </ButtonSecondary>

        <ButtonPrimary htmlType="submit" type="primary" loading={isSubmitting}>
          <span>Salvar</span>
        </ButtonPrimary>
      </ContainerButtons>

      <ModalSuccess
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Edição de evento finalizada!"
        subtitle="Clique em OK para prosseguir."
      />
    </EditingBoardContent>
  );
}

import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";

import moment from "moment";

import { SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { CreateTicketEventFormData } from "types";

import { renderCurrency } from "utils/currency";

import { createTicketEventFormSchema } from "lib/validations/createTicket";
import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";
import { AddTicket, EditTicket } from "services/Hook/Event/Ticket";
import { convertDecimalStringToInt } from "utils/convertDecimalStringToInt";

interface useCreateTicketProps {
  event_id?: string;
  handleShowModal?: () => void;
}

export function useCreateTicket({
  event_id,
  handleShowModal,
}: useCreateTicketProps) {
  const {
    ticket,
    setSelectedTicket,
    setIsOpenDrawerTicket,
    setIsOpenModalTicket,
    isOpenDrawerTicket,
    isOpenModalTicket,
  } = useCreateAndEditTicket();

  const [file, setFile] = useState<any>(null);
  const [fetching, setFetching] = useState(false);

  const { mutate } = useSWRConfig();

  const {
    register,
    reset,
    control,
    watch,
    setValue,
    setError,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<CreateTicketEventFormData>({
    resolver: yupResolver(createTicketEventFormSchema),
    defaultValues: {
      type_sale: 1,
      quantity: 100,
      quantity_min: 1,
      quantity_max: 1,
      type_ticket: 1,
      free_ticket: false,
    },
  });

  useEffect(() => {
    if (ticket === null) {
      reset({
        name: "",
        quantity: 100,
        price: "",
        description: "",
        absorb_rate: undefined,
        type_sale: undefined,
        type_ticket: 1,
        quantity_min: 1,
        quantity_max: 1,
        guest: undefined,
        guest_quantity: undefined,
        transfer: undefined,
        term_required: undefined,
        term: undefined,
        sale_begin_date: "",
        sale_begin_hour: "",
        sale_ticket: undefined,
        sale_end_date: "",
        sale_end_hour: "",
        free_ticket: false,
      });

      return;
    }

    if (!!ticket) {
      let price = 0;

      if (ticket?.price) {
        let regex = /[.,\s]/g;

        const fixedValue = parseFloat(String(ticket?.price)).toFixed(2);

        let value = fixedValue.toString();

        const valueReplaced = value.replace(regex, "");

        price = parseInt(valueReplaced);
      }

      let dateComponent = "";

      if (ticket?.type_sale === 1) {
        const date = moment(ticket.sale_begin_date);
        dateComponent = date.utc().format("DD/MM/YYYY");

        const hour = moment(ticket?.begin_hour);
        const hourComponent = hour.utc().format("hh:mm");

        // formattedDate: format(parseISO(event.begin_date), "dd'/'MM'/'y'", {
        //   locale: ptBR,
        // })
      }

      const endDate = moment(ticket.sale_end_date);
      const dateEndComponent = endDate.utc().format("DD/MM/YYYY");

      reset({
        name: ticket?.name,
        quantity: ticket?.quantity,
        price: renderCurrency(String(price)),
        description: ticket?.description,
        absorb_rate: ticket?.absorb_rate,
        type_sale: ticket?.type_sale,
        type_ticket: 1,
        quantity_min: ticket?.quantity_min,
        quantity_max: ticket?.quantity_max,
        term_required: ticket?.term_required,
        term: ticket?.term,
        guest: ticket?.guest,
        guest_quantity: ticket?.guest_quantity,
        transfer: ticket?.transfer,
        ...(ticket?.type_sale === 1 && { sale_begin_date: dateComponent }),
        ...(ticket?.type_sale === 1 && {
          sale_begin_hour: ticket?.formattedBeginHour,
        }),
        ...(ticket?.type_sale === 2 && { sale_ticket: ticket?.sale_ticket }),
        sale_end_date: dateEndComponent,
        sale_end_hour: ticket?.formattedEndHour,
      });
    }
  }, [isOpenDrawerTicket, isOpenModalTicket, reset, ticket]);

  const handleAddTicket: SubmitHandler<CreateTicketEventFormData> = async ({
    ...params
  }) => {
    setFetching(true);

    const {
      type_sale,
      name,
      description,
      quantity,
      free_ticket,
      price,
      half_price,
      sale_ticket,
      sale_begin_date,
      sale_end_date,
      sale_begin_hour,
      sale_end_hour,
      transfer,
      absorb_rate,
      quantity_max,
      quantity_min,
      archive,
      term_required,
      term,
      guest,
      guest_quantity,
    } = params;

    if (free_ticket) {
      const halfPriceToFloat = convertDecimalStringToInt(half_price);

      if (halfPriceToFloat <= 0) {
        setError("half_price", { message: "O valor deve ser maior do que 0" });
      }
    }

    if (!free_ticket) {
      const priceToFloat = convertDecimalStringToInt(price);

      if (priceToFloat < 500) {
        setError("price", { message: "O valor mínimo é de R$ 5,00" });
        setFetching(false);
        setFocus("price");

        return;
      }
    }

    try {
      let dateComponentBegin = null;

      if (type_sale === 1) {
        const newDateBegin = sale_begin_date?.split("/") ?? "";

        dateComponentBegin = `${newDateBegin[2]}-${newDateBegin[1]}-${newDateBegin[0]}`;
      }

      const newDateEnd = sale_end_date?.split("/") ?? "";

      const dateComponentEnd = `${newDateEnd[2]}-${newDateEnd[1]}-${newDateEnd[0]}`;

      const priceToFloat = convertDecimalStringToInt(price);

      const credentials = {
        event_id,
        type: 1,
        type_ticket: 1,
        discount: 0,
        type_sale,
        name,
        description,
        quantity,
        price: priceToFloat,
        ...(type_sale === 1 && { sale_begin_date: dateComponentBegin }),
        ...(type_sale === 1 && {
          sale_begin_hour: `${dateComponentBegin} ${sale_begin_hour}`,
        }),
        ...(type_sale === 2 && { sale_ticket }),
        sale_end_date: dateComponentEnd,
        sale_end_hour: `${dateComponentEnd} ${sale_end_hour}`,
        term_required,
        term,
        transfer,
        status: true,
        absorb_rate,
        quantity_max,
        quantity_min,
        archive: archive ?? "",
        guest,
        guest_quantity,
      };

      if (ticket && setSelectedTicket) {
        await EditTicket({ id: ticket._id, credentials });

        mutate(`/event-ticket/search/${event_id}`);

        reset();

        setSelectedTicket(null);

        setIsOpenDrawerTicket(false);
        setIsOpenModalTicket(false);

        if (handleShowModal) {
          handleShowModal();
        }

        return;
      }

      await AddTicket({ credentials });

      mutate(`/event-ticket/search/${event_id}`);

      reset();

      setIsOpenDrawerTicket(false);
      setIsOpenModalTicket(false);

      if (handleShowModal) {
        handleShowModal();
      }
    } catch (err) {
      alert(err);
    } finally {
      setFetching(false);
    }
  };

  return {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    errors,
    isSubmitting,
    handleAddTicket,
    setFile,
    file,
    reset,
    fetching,
    setError,
  };
}

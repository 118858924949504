import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { Container } from "./styles";

const { Dragger } = Upload;

type UploadFileProps = UploadProps;

function UploadFile({ ...props }: UploadFileProps) {
  return (
    <Container>
      <p className="label">Selecione o arquivo .csv</p>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Clique ou arraste o arquivo até esta área
        </p>
        <p className="ant-upload-hint">
          Atenção. A planilha deve conter as seguintes colunas obrigatórias:
          <br />
          - user_name (Nome do comprador)
          <br />
          - user_email (E-mail do comprador)
          <br />- user_document (CPF ou CPNJ do comprador)
        </p>
      </Dragger>
    </Container>
  );
}

export default UploadFile;

import styled, { css } from "styled-components";
import { Button, Modal } from "antd";
import { Colors } from "constants/colors";

export const Container = styled.header`
  position: fixed;
  display: flex;
  width: 100%;
  background-color: ${Colors.white};
  display: flex;
  justify-content: center;
  margin: 0 auto;
  z-index: 99;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);

  @media (max-width: 1024px) {
    flex-direction: column;
    background-color: transparent;
    z-index: 99;
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  height: 100px;

  z-index: 99;

  background-color: ${Colors.white};
  align-items: center;
  justify-content: space-between;

  padding-right: 38px;
  padding-left: 38px;

  @media (max-width: 1024px) {
    height: 64px;
    padding: 0;
  }

  div.mobile {
    display: flex;
    width: 100%;

    align-items: center;
    padding: 0px 31px 0px 31px;
    justify-content: space-between;

    div.icons {
      width: 55px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;

      svg.left {
        margin-right: 18px;
      }

      svg.home {
        margin-top: 5px;
      }
    }

    img {
      max-width: 34px;
      max-height: 34px;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  div.content {
    width: 100%;
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: space-between;

    div.logo-main {
      img {
        cursor: pointer;
        min-height: 87px;
        min-width: 163px;
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }

    form {
      display: flex;
      align-items: center;
      justify-content: space-between;

      max-width: 483px;
      width: 100%;
      height: 45px;
      border: 1px solid ${Colors.border};
      border-radius: 10px;
      margin-left: 55px;

      @media (max-width: 1100px) {
        width: 80%;
      }

      /* @media (max-width: 1180px) {
        display: none;
      } */

      div.dropdown {
        min-width: 33%;
        height: 45px;
        cursor: pointer;
        border-right: 1px solid ${Colors.border};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 12px 0px 12px;

        @media (max-width: 1300px) {
          width: 20%;
        }

        @media (max-width: 1100px) {
          width: 20%;
          svg {
            width: 20px;
            height: 20px;
          }
        }

        div.icon {
          margin-top: 4px;
        }

        @media (max-width: 1300px) {
          align-items: center;
          justify-content: center;

          div.icon {
            display: none;
          }

          div.text {
            display: none;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 12px;

          margin-top: 6px;
          color: ${Colors.primary100};

          @media (max-width: 1304px) {
            font-size: 10px;
          }

          @media (max-width: 1100px) {
            font-size: 8px;
          }
        }
      }

      div.searchInput {
        width: 49%;
        height: 100%;

        overflow-x: hidden;

        input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0px 14px 0px 0px;

          caret-color: ${Colors.primary100};

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;

          ::placeholder {
            color: ${Colors.gray};
          }

          color: ${Colors.gray};
          opacity: 0.5;
        }
      }

      button.search {
        border: 0;
        background-color: transparent;
        outline: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12%;
        height: 100%;
        border-left: 1px solid ${Colors.border};

        @media (max-width: 1300px) {
          width: 15%;
        }
      }
    }

    div.buttons {
      z-index: 99;
      width: 530px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 2rem;

      div.buttons-account {
        display: flex;
        flex-direction: row;

        div.account-container {
        }
      }

      > a {
        display: block;
        color: ${Colors.primary100};
        font-weight: bold;
        white-space: nowrap;
        font-size: 14px;

        text-decoration: underline;

        /* &:hover {
          text-decoration: underline;
        } */
      }

      button.cart {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        min-width: 45px;
        min-height: 45px;
        margin-left: 20px;
        background: ${Colors.primary10};
        border-radius: 8px;
      }
    }
  }
`;

export const DropDown = styled.div`
  background-color: ${Colors.white};
  width: 100%;
  min-height: 164px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  z-index: 98;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px 28px 0px 28px;

  form {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid ${Colors.border};
    margin-top: 18px;

    div.dropdown {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 14px 0px 14px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      h3 {
        font-size: 12px;
        line-height: 22px;
        font-weight: bold;
        color: ${Colors.primary100};
        text-align: center;
        margin-left: 14px;
        margin-top: 3px;
      }
    }

    div.searchInput {
      width: 100%;
      height: 100%;
      input {
        height: 38px;
        width: 90%;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px 2px 0px 28px;
        font-size: 14px;
        caret-color: ${Colors.primary100};
      }
    }

    button.search {
      border: 0;
      outline: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47px;
      height: 100%;
      border-left: 1px solid ${Colors.border};
    }
  }
`;

export const SearchItem = styled.div`
  width: 100%;
`;

export const Localization = styled.div`
  width: 100%;
  padding-bottom: 27px;

  h2 {
    color: ${Colors.primary100};
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    margin-top: 18px;
  }

  input {
    width: 100%;
    height: 38px;
    margin-top: 12px;
    border-radius: 8px;
    outline: none;
    border: 1px solid ${Colors.border};
    padding: 0px 2px 0px 28px;
    font-size: 14px;
    caret-color: ${Colors.primary100};
  }
`;

export const MainCardBorder = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: row;

  height: 63px;
  border-top: 1px solid ${Colors.border};
  border-bottom: 1px solid ${Colors.border};
  cursor: pointer !important;

  div.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 13px;

    strong.atual {
      color: ${Colors.secondary100};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    span {
      color: ${Colors.gray};
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

export const NormalCardBorder = styled.div`
  cursor: pointer;
  width: 100%;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid ${Colors.border};

  h3.normal {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 3px;
    margin-left: 13px;
    color: ${Colors.secondary100};
  }
`;

export const Sidebar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;

  div.main {
    width: 100%;
    height: 100%;
    background-color: ${Colors.white} !important;

    div.links {
      width: 100%;
      padding-bottom: 19px;
      border-bottom: 1px solid ${Colors.border};
      margin-top: 20px;

      div {
        padding: 17px 0px 0px 32px;
      }

      div.clickLink {
        padding: 0;
      }

      h2 {
        color: ${Colors.secondary100};
        font-weight: bold;

        font-family: Cairo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
      }

      h3 {
        color: ${Colors.primary100};
        font-weight: bold;
        font-family: Cairo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
      }
    }

    div.login {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      padding-left: 30px;

      button.cart {
        cursor: pointer;
        border: none;
        width: 108px;
        height: 32px;

        background: ${Colors.primary10};
        border-radius: 5px;

        font-weight: bold;
        font-size: 12px;
        line-height: 22px;

        color: ${Colors.primary100};
      }

      button.account {
        cursor: pointer;
        border: none;
        width: 118px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 17px 14px;
        background: ${Colors.primary100};
        box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
        border-radius: 5px;

        font-weight: bold;
        font-size: 12px;
        line-height: 22px;
        margin-top: 24px;

        color: ${Colors.white};
      }
    }
  }
`;

interface ButtonOrganizerProps {
  active: boolean;
}

export const ButtonOrganizer = styled.button<ButtonOrganizerProps>`
  background: ${Colors.white};

  /* border: 1px solid ${Colors.primary100}; */
  box-sizing: border-box;

  border: ${(props) =>
    props.active ? `1px solid ${Colors.primary100}` : "1px solid transparent"};

  /* ${(props) =>
    props.active &&
    css`
      border: 1px solid ${Colors.primary100} !important;
    `} */

  border-radius: 14px;

  cursor: pointer;
  width: 179px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 20px; */
  padding: 17px 14px;

  @media (max-width: 1100px) {
    margin-left: 2px;
  }

  border-radius: 8px;
  z-index: 2;

  font-weight: bold;
  font-size: 20px;

  color: ${Colors.white};

  font-weight: bold;
  font-size: 15px;
  line-height: 160%;

  text-decoration-line: underline;

  color: ${Colors.primary100};
`;

export const AccountDropDownOrganizer = styled.div`
  display: block;
  position: absolute;
  background-color: ${Colors.white};
  width: 179px;

  z-index: -1;
  margin-top: -5px;

  border: 1px solid ${Colors.black10};

  border-radius: 0px 0px 14px 14px;

  border-radius: 0px 0px 14px 14px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.last {
      border: 0;
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

interface ButtonAccountProps {
  active: boolean;
}

export const ButtonAccount = styled.button<ButtonAccountProps>`
  cursor: pointer;
  border: none;
  width: 179px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;

  z-index: 3;

  padding: 17px 14px;
  background: ${Colors.primary100};

  /* ${(props) =>
    !props.active &&
    css`
      box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    `} */

  border-radius: 8px;

  font-weight: bold;
  font-size: 16px;

  color: ${Colors.white};
`;

interface AccountDropDownProps {
  userLogged: boolean;
}

export const AccountDropDown = styled.div<AccountDropDownProps>`
  display: block;
  position: absolute;
  background-color: ${Colors.white};
  width: 179px;
  height: ${(props) => (props.userLogged ? "272px" : "180px")};

  border: 1px solid ${Colors.black10};

  z-index: -1;
  margin-left: 20px;

  margin-top: -5px;

  border-radius: 0px 0px 14px 14px;

  ul {
    margin-top: 18px;
    position: relative;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: flex-start;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        margin-top: 0px;
        margin-left: 17px;
      }
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const DropdownButton = styled(Button)`
  /* width: 60px;
  height: 60px; */
  border-radius: 14px;
  width: 2.81rem;
  height: 2.81rem;
  border-radius: 0.65rem;
  background-color: #fbe5ed;
  /* box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15); */
  font: 700 2.8rem Cairo, "Cairo", sans-serif;
  color: ${Colors.primary100};
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 3.2rem; */
  text-align: center;
  border: none;
  svg {
    font-size: 24px;
  }
  :hover,
  :active,
  :focus {
    background-color: #fbe5ed;
    font: 700 2.8rem Cairo, "Cairo", sans-serif;
    color: ${Colors.primary100};
    border-color: ${Colors.tertiary100};
  }
`;

export const DropdownMenu = styled.div`
  border-radius: 14px;
  background-color: #fff;
  width: fit-content;
  padding: 30px;
  margin-top: 32px;
  position: relative;
  box-shadow: 0px 5px 30px 0px #12192266;
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 32px solid white;
    right: 20px;
    top: -16px;
  }
  .your-order {
    font: 600 1.4rem Poppins, "Poppins", sans-serif;
    color: ${Colors.secondary100};
    margin-bottom: 1rem;
  }
  .subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    color: ${Colors.black};
    .label {
      font: 400 1rem Poppins, "Poppins", sans-serif;
    }
    .value {
      font: 700 1.2rem Cairo, "Cairo", sans-serif;
    }
  }
  .confirm-button {
    width: 100%;
    height: 3.4rem;
    border-radius: 0.8rem;
    background-color: ${Colors.primary100};
    /* box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15); */
    font: 700 1.2rem Cairo, "Cairo", sans-serif;
    color: #fff;
    border: none;
    /* :hover,
    :active,
    :focus {
      background-color: ${Colors.primary100};
      font: 700 2.8rem Cairo, "Cairo", sans-serif;
      color: #fff;
      border: none;
    } */
  }
`;

export const TicketContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .icon-container {
    display: flex;
    margin-right: 64px;
    svg {
      margin-top: 8px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: ${Colors.gray10};
    }
    .name-and-price {
      text-align: start;
      p {
        font: 400 2.4rem Poppins, "Poppins", sans-serif;
        margin: 5px 0;
      }
      p:first-child {
        font-weight: 500;
        font-size: 16px;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${Colors.secondary100};
      }
      p:last-child {
        font-weight: 600;
        font-size: 14px;
        color: ${Colors.black};
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: flex-end;
  @media screen and (max-width: 580px) {
    display: none;
  }
`;

export const ActionButton = styled.a`
  width: auto;
  height: 60px;
  border-radius: 14px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 2.8rem Cairo, "Cairo", sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-right: 8px;
  &.vertical {
    display: flex !important;
    padding: 0 10px;
    margin-right: 0;
    svg {
      margin-right: 6px;
    }
  }
  /* :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    font: 700 2.8rem Cairo, "Cairo", sans-serif;
    color: #fff;
    border-color: ${Colors.tertiary100};
  } */
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 1024px) {
    display: none;
    &.vertical {
      display: flex !important;
    }
  }
`;

export const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-input {
    width: 4rem;
    height: 2.4rem;
    padding: 4px 8px;
    border: none;
    background-color: #f6f6f6;
    border-radius: 12px;
    font: 600 1.2rem/1.6rem Cairo, "Cairo", sans-serif;
    &:focus {
      box-shadow: none;
    }
  }
  button {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    border-radius: 12px;
    background-color: ${Colors.primary100};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    :hover,
    :active,
    :focus {
      background-color: ${Colors.primary100};
      color: #fff;
      outline: none;
    }
    &.add {
      background-color: ${Colors.green10};
    }
    &.remove {
      background-color: ${Colors.red10};
      margin-right: 10px;
    }
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 94px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 0px;
  }

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content {
    margin-top: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90} #292c42;

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90} #292c42;
      }
    }
  }

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    @media (max-width: 800px) {
      margin-top: 25px;
    }

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;

      @media (max-width: 800px) {
        min-width: 40%;
      }
    }
  }

  div.download-app {
    width: 100%;
    margin-top: 36px;
    align-items: center;
    justify-content: center;

    padding-left: 10px;
    padding-right: 10px;

    h5 {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.black90};
    }

    div.row {
      width: 100%;
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      justify-content: center;

      img {
        cursor: pointer;
        min-width: 189px;
        min-height: 128px;

        @media (max-width: 800px) {
          min-width: 129px;
          min-height: 78px;
        }
      }
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

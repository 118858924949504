import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useSearchTeamMember() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams(
        {
          ...Object.fromEntries([...searchParams]),
          page: String(currentPage),
        },
        {
          replace: true,
          state: {
            scroll: false,
          },
        }
      );
    },
    [searchParams, setSearchParams]
  );

  return {
    page,
    onChange,
  };
}

import { BiTime } from "react-icons/bi";

import {
  RiCalendarCheckFill,
  RiCoupon2Line,
  RiIncreaseDecreaseLine,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";

import { IoMdInformationCircleOutline } from "react-icons/io";

import moment from "moment";

import { formatPrice } from "utils/formatPrice";

import {
  Radio,
  RadioChangeEvent,
  Space,
  Switch,
  TimePicker,
  DatePicker,
  Select,
  Spin,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { Controller } from "react-hook-form";
import { useTicketEvent } from "services/Hook/Event/Ticket";
import { Event, TicketEvent } from "types";
import { useCreateTicket } from "hooks/useCreateTicket";
import { Colors } from "constants/colors";

import {
  ButtonClose,
  ButtonOk,
  CardContentModal,
  ContainerButtonsModal,
  ContainerCheck,
  ContainerInputFull,
  ContainerRadio,
  ContainerSelect,
  ContainerSubLabelPrice,
  ContainerSwitch,
  Divider,
  DividerWithoutBorder,
  FormLabel,
  GridInputs,
  LabelSelect,
  LabelTitle,
  SubLabelPrice,
  SubLabelTooltip,
  TitleLabelPrice,
  TitleModal,
  TitlePrice,
  TitleSwitch,
} from "./styles";

import { InputForm } from "components/Form/InputForm";
import { TextAreaForm } from "components/Form/TextAreaForm";
import { StyledSelect } from "components/ModalConfirmReverse/styles";
import { BorderSelect } from "components/SelectHeaderFilter/styles";
import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuthMyEvent } from "services/event";
import { errorNotification } from "components/Notification";
import { ModalSuccess } from "components/ModalSuccess";
import { useDisclosure } from "hooks/useDisclosure";

const { Option } = Select;

interface TicketsEventInterface {
  tickets: TicketEvent[];
}

interface ModalAddAndEditTicketProps {
  event_id: string;
}

const dateFormat = "DD/MM/YYYY";
const formatHour = "HH:mm";

export function CreateAddAndEditTicket({
  event_id,
}: ModalAddAndEditTicketProps) {
  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();
  const {
    ticket,
    setIsOpenDrawerTicket,
    setIsOpenModalTicket,
    setSelectedTicket,
  } = useCreateAndEditTicket();
  const { id, nickname } = useParams();
  const [event, setEvent] = useState<Event | undefined>();
  const [isFetchingEvent, setIsFetchingEvent] = useState(false);

  const { data: dataTickets } = useTicketEvent<TicketsEventInterface>(
    event_id === "undefined" ? nickname : event_id
  );

  const {
    control,
    handleAddTicket,
    register,
    errors,
    handleSubmit,
    isSubmitting,
    setValue,
    watch,
    reset,
    fetching,
  } = useCreateTicket({
    event_id: event_id === "undefined" ? nickname : event_id,
    handleShowModal: showModal,
  });

  const isDisabled = fetching || isSubmitting || isFetchingEvent;

  const watchPrice = watch("price");
  const watchFreeTicket = watch("free_ticket");
  const watchTypeSale = watch("type_sale");
  const watchGuest = watch("guest");
  const watchCheckedHasArquive = watch("checked_has_arquive");
  const watchCheckedTermsRequired = watch("term_required");
  const watchAbsorbRate = watch("absorb_rate");

  const formattedPrice = watchPrice
    ? Number(watchPrice.replace(/\D/g, "")) / 100
    : 0;

  const rate = formattedPrice * ((event?.fee ?? 0) / 100);
  const buyerRate = watchAbsorbRate ? formatPrice(0) : formatPrice(rate);
  const totalPurchase = watchAbsorbRate
    ? formatPrice(formattedPrice)
    : formatPrice(formattedPrice + rate);
  const totalReceivable = watchAbsorbRate
    ? formatPrice(formattedPrice - rate)
    : formatPrice(formattedPrice);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handleGetEvent = useCallback(async (eventId: string) => {
    try {
      setIsFetchingEvent(true);

      const eventResponse = await getAuthMyEvent(eventId);

      setEvent(eventResponse);
    } catch {
      errorNotification("Ocorreu um erro ao buscar a taxa do evento.");
    } finally {
      setIsFetchingEvent(false);
    }
  }, []);

  useEffect(() => {
    if (ticket === null) {
      reset({});
    }
  }, [reset, ticket]);

  useEffect(() => {
    if (id) {
      handleGetEvent(id);

      return;
    }

    if (nickname) {
      handleGetEvent(nickname);

      return;
    }

    if (event_id && event_id !== "undefined") {
      handleGetEvent(event_id);

      return;
    }
  }, [id, nickname, event_id, handleGetEvent]);

  return (
    <Spin spinning={isDisabled} indicator={antIcon}>
      <form onSubmit={handleSubmit(handleAddTicket)}>
        <CardContentModal>
          <TitleModal>Dados do ingresso</TitleModal>

          <Divider />
          {!ticket && !isSubmitting && (
            <>
              <ContainerSwitch>
                <Controller
                  control={control}
                  name="free_ticket"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => {
                        onChange(e);

                        setValue("price", "0");
                        setValue("half_price", "0");
                      }}
                    />
                  )}
                />

                <TitleSwitch>Ingresso gratuito</TitleSwitch>
              </ContainerSwitch>
              <DividerWithoutBorder />
            </>
          )}

          <InputForm
            isFull
            {...register("name")}
            error={errors.name}
            icon={<RiCoupon2Line size={17} color={Colors.secondary40} />}
            label="Nome do ingresso"
          />

          <GridInputs>
            <InputForm
              isFull
              type="number"
              {...register("quantity")}
              error={errors.quantity}
              icon={
                <RiIncreaseDecreaseLine size={17} color={Colors.secondary40} />
              }
              label="Quantidade"
            />

            <InputForm
              isFull
              hasPrefix
              {...register("price")}
              mask="price"
              error={errors.price}
              icon={
                <RiMoneyDollarCircleLine size={17} color={Colors.secondary40} />
              }
              isEditing={!watchFreeTicket}
              label="Valor"
              disabled={!ticket && !isSubmitting}
            />
          </GridInputs>

          {watchPrice && (
            <>
              <TitleLabelPrice>TOTAL DA VENDA</TitleLabelPrice>
              <TitlePrice>{totalPurchase}</TitlePrice>
              <ContainerSubLabelPrice>
                <IoMdInformationCircleOutline
                  color={Colors.secondary40}
                  size={18}
                />
                <SubLabelPrice>Taxa comprador: {buyerRate}</SubLabelPrice>
              </ContainerSubLabelPrice>{" "}
              <Divider />
              <TitleLabelPrice>TOTAL A RECEBER</TitleLabelPrice>
              <TitlePrice>{totalReceivable}</TitlePrice>
            </>
          )}

          <DividerWithoutBorder />

          <ContainerSwitch>
            <Controller
              control={control}
              name="absorb_rate"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />

            <TitleSwitch>Absorver taxa de serviço</TitleSwitch>
          </ContainerSwitch>

          <Divider />

          <LabelTitle>Tipo de venda desse ingresso</LabelTitle>

          <ContainerCheck>
            <Controller
              control={control}
              name="type_sale"
              defaultValue={1}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  defaultValue={value}
                >
                  <Space direction="horizontal">
                    <Radio value={1}>Por data</Radio>

                    {dataTickets && dataTickets.tickets.length > 0 ? (
                      <Radio value={2}>Por lote</Radio>
                    ) : null}
                  </Space>
                </Radio.Group>
              )}
            />
          </ContainerCheck>

          <Divider />

          {watchTypeSale && watchTypeSale === 1 ? (
            <>
              <LabelTitle>Período das vendas deste ingresso</LabelTitle>

              <GridInputs>
                <ContainerInputFull isFull error={!!errors.sale_begin_date}>
                  <FormLabel>
                    <RiCalendarCheckFill size={17} color={Colors.secondary40} />
                    <h2>Data inicial</h2>
                  </FormLabel>

                  <Controller
                    control={control}
                    name="sale_begin_date"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <DatePicker
                        format={dateFormat}
                        placeholder="Selecione uma data inicial"
                        suffixIcon={false}
                        style={{
                          height: "auto",
                          width: "auto",
                          border: "none",
                          borderRadius: "0px",
                          cursor: "pointer",
                          fontSize: "17px",
                          margin: "0px",
                          padding: "0px",
                        }}
                        ref={ref}
                        value={value ? moment(value, dateFormat) : null}
                        onChange={(date: unknown, dateString: string) => {
                          if (!dateString) {
                            onChange(null);
                            return;
                          }

                          onChange(dateString);
                        }}
                      />
                    )}
                  />

                  {!!errors.sale_begin_date && (
                    <span className="error">
                      {errors.sale_begin_date.message}
                    </span>
                  )}
                </ContainerInputFull>

                <InputForm
                  label="Horário de início"
                  isFull
                  mask="hour"
                  icon={<BiTime size={17} color={Colors.secondary40} />}
                  {...register("sale_begin_hour")}
                  placeholder="Selecione um horário inicial"
                  error={errors?.sale_begin_hour}
                />
              </GridInputs>

              <GridInputs>
                <ContainerInputFull isFull error={!!errors.sale_end_date}>
                  <FormLabel>
                    <RiCalendarCheckFill size={17} color={Colors.secondary40} />
                    <h2>Data de término</h2>
                  </FormLabel>

                  <Controller
                    control={control}
                    name="sale_end_date"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <DatePicker
                        format={dateFormat}
                        placeholder="Selecione uma data final"
                        suffixIcon={false}
                        style={{
                          height: "auto",
                          width: "auto",
                          border: "none",
                          borderRadius: "0px",
                          cursor: "pointer",
                          fontSize: "17px",
                          margin: "0px",
                          padding: "0px",
                        }}
                        ref={ref}
                        value={value ? moment(value, dateFormat) : null}
                        onChange={(date: unknown, dateString: string) => {
                          if (!dateString) {
                            onChange(null);
                            return;
                          }

                          onChange(dateString);
                        }}
                      />
                    )}
                  />

                  {!!errors.sale_end_date && (
                    <span className="error">
                      {errors.sale_end_date.message}
                    </span>
                  )}
                </ContainerInputFull>

                <InputForm
                  label="Horário de término"
                  isFull
                  mask="hour"
                  icon={<BiTime size={17} color={Colors.secondary40} />}
                  {...register("sale_end_hour")}
                  placeholder="Selecione um horário final"
                  error={errors?.sale_end_hour}
                />
              </GridInputs>
            </>
          ) : null}

          {watchTypeSale && watchTypeSale === 2 ? (
            <>
              <LabelTitle>
                Início das vendas quando este ingresso esgotar
              </LabelTitle>

              <ContainerSelect>
                <LabelSelect>Ingresso</LabelSelect>

                <Controller
                  control={control}
                  name="sale_ticket"
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <BorderSelect>
                      <StyledSelect
                        size="large"
                        labelInValue
                        bordered={false}
                        placeholder="Selecione uma opção"
                        value={value}
                        ref={ref}
                        style={{
                          width: "100%",
                        }}
                        onChange={(e: any) => {
                          onChange(e.value);
                        }}
                      >
                        {dataTickets?.tickets?.map((ticket) => (
                          <Option key={ticket.id} value={ticket.id}>
                            {ticket.name}
                          </Option>
                        ))}
                      </StyledSelect>
                    </BorderSelect>
                  )}
                />

                {!!errors.sale_ticket && (
                  <span className="error">{errors.sale_ticket.message}</span>
                )}
              </ContainerSelect>

              <GridInputs>
                <ContainerInputFull isFull error={!!errors.sale_end_date}>
                  <FormLabel>
                    <RiCalendarCheckFill size={17} color={Colors.secondary40} />
                    <h2>Data de término</h2>
                  </FormLabel>

                  <Controller
                    control={control}
                    name="sale_end_date"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <DatePicker
                        format={dateFormat}
                        placeholder="Selecione uma data final"
                        suffixIcon={false}
                        style={{
                          height: "auto",
                          width: "auto",
                          border: "none",
                          borderRadius: "0px",
                          cursor: "pointer",
                          fontSize: "17px",
                          margin: "0px",
                          padding: "0px",
                        }}
                        ref={ref}
                        value={value ? moment(value, dateFormat) : null}
                        onChange={(date: unknown, dateString: string) => {
                          if (!dateString) {
                            onChange(null);
                            return;
                          }

                          onChange(dateString);
                        }}
                      />
                    )}
                  />

                  {!!errors.sale_end_date && (
                    <span className="error">
                      {errors.sale_end_date.message}
                    </span>
                  )}
                </ContainerInputFull>

                <ContainerInputFull isFull error={!!errors.sale_end_hour}>
                  <FormLabel>
                    <BiTime size={17} color={Colors.secondary40} />

                    <h2>Horário de término</h2>
                  </FormLabel>

                  <Controller
                    control={control}
                    name="sale_end_hour"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <TimePicker
                        format={formatHour}
                        placeholder="Selecione um horário final"
                        suffixIcon={false}
                        style={{
                          height: "auto",
                          width: "auto",
                          border: "none",
                          borderRadius: "0px",
                          cursor: "pointer",
                          fontSize: "17px",
                          margin: "0px",
                          padding: "0px",
                        }}
                        ref={ref}
                        onChange={(date: unknown, timeString: string) => {
                          if (!timeString) {
                            onChange(null);
                            return;
                          }

                          onChange(timeString);
                        }}
                        value={value ? moment(value, formatHour) : null}
                      />
                    )}
                  />

                  {!!errors.sale_end_hour && (
                    <span className="error">
                      {errors.sale_end_hour.message}
                    </span>
                  )}
                </ContainerInputFull>
              </GridInputs>
            </>
          ) : null}

          <LabelTitle>Quantidade permitida por compra</LabelTitle>

          <GridInputs>
            <InputForm
              isFull
              type="number"
              {...register("quantity_min")}
              error={errors.quantity_min}
              placeholder="Insira ou selecione uma quantidade mínima"
              icon={
                <RiIncreaseDecreaseLine size={17} color={Colors.secondary40} />
              }
              label="Quantidade mínima"
            />

            <InputForm
              isFull
              type="number"
              {...register("quantity_max")}
              error={errors.quantity_max}
              placeholder="Insira ou selecione uma quantidade máxima"
              icon={
                <RiIncreaseDecreaseLine size={17} color={Colors.secondary40} />
              }
              label="Quantidade máxima"
            />
          </GridInputs>

          <Divider />

          <LabelTitle>Descrição do ingresso (opcional)</LabelTitle>

          <TextAreaForm
            isFull
            {...register("description")}
            placeholder=""
            error={errors.description}
          />

          <DividerWithoutBorder />

          <Divider />

          <ContainerSwitch>
            <Controller
              control={control}
              name="guest"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />

            <div className="column">
              <TitleSwitch>Permitir acompanhante</TitleSwitch>

              <p>
                Permitir que este ingresso dê direito a 1 ou mais aompanhantes.
              </p>
            </div>
          </ContainerSwitch>

          <DividerWithoutBorder />

          {watchGuest && (
            <InputForm
              isFull
              {...register("guest_quantity")}
              type="number"
              placeholder="Insira ou selecione uma quantidade de acompanhantes"
              error={errors.guest_quantity}
              icon={
                <RiIncreaseDecreaseLine size={17} color={Colors.secondary40} />
              }
              label="Quantidade de acompanhantes"
            />
          )}

          <Divider />

          <ContainerSwitch>
            <Controller
              control={control}
              name="checked_has_arquive"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />

            <div className="column">
              <TitleSwitch>Anexar link externo</TitleSwitch>

              <p>
                Anexe um <strong>link externo</strong>, ele será enviado por
                e-mail junto com o ingresso na conclusão da compra.
              </p>
            </div>
          </ContainerSwitch>

          {watchCheckedHasArquive && (
            <>
              <DividerWithoutBorder />

              <InputForm
                isFull
                {...register("archive")}
                placeholder="Insir a URL do link externo..."
                error={errors.archive}
                icon={<RiCoupon2Line size={17} color={Colors.secondary40} />}
                label="Link externo"
              />
            </>
          )}

          <Divider />

          <ContainerSwitch>
            <Controller
              control={control}
              name="term_required"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />

            <div className="column">
              <TitleSwitch>Termos de uso do ingresso</TitleSwitch>

              <p>
                Adicione <strong>termos de uso</strong> para este ingresso, o
                aceite será obrigatório antes de concluir a compra.
                <br></br>
                Em caso de vendas importadas e webhooks, um{" "}
                <strong>link para aceite</strong> dos termos será enviado antes
                do ingresso final.
              </p>
            </div>
          </ContainerSwitch>

          {watchCheckedTermsRequired && (
            <>
              <LabelTitle>Adicione os termos do seu ingresso</LabelTitle>

              <TextAreaForm
                isFull
                {...register("term")}
                placeholder="Insira os termos de uso..."
                error={errors.term}
              />
              <DividerWithoutBorder />
            </>
          )}

          <Divider />

          <LabelTitle>Permitir transferência do ingresso</LabelTitle>

          <SubLabelTooltip>
            Permitir a transferência autoriza o comprador a alterar a
            titularidade do mesmo caso deseje.
          </SubLabelTooltip>

          <ContainerCheck>
            <Controller
              control={control}
              name="transfer"
              defaultValue={true}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    onChange(e.target.value);
                  }}
                  value={value}
                >
                  <ContainerRadio>
                    <Radio value={true}>Permitir transferência</Radio>
                    <Radio value={false}>Não permitir a transfêrencia</Radio>
                  </ContainerRadio>
                </Radio.Group>
              )}
            />
          </ContainerCheck>
        </CardContentModal>

        <ContainerButtonsModal>
          <ButtonClose
            type="button"
            onClick={() => {
              setIsOpenDrawerTicket(false);
              setIsOpenModalTicket(false);
              setSelectedTicket(null);
            }}
          >
            <span>Cancelar</span>
          </ButtonClose>

          <ButtonOk disabled={isDisabled} type="submit">
            {!ticket && !isDisabled && <span>Salvar ingresso</span>}

            {ticket && !isDisabled && <span>Editar ingresso</span>}

            {isDisabled && <Spin spinning={isDisabled} indicator={antIcon} />}
          </ButtonOk>
        </ContainerButtonsModal>

        <ModalSuccess
          handleOk={handleOk}
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
          title="Edição de evento finalizada!"
          subtitle="Clique em OK para prosseguir."
        />
      </form>
    </Spin>
  );
}

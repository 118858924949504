import { authClient } from "services/authClient";

export async function doImportPurchases(body: FormData) {
  const { data } = await authClient.post(`/purchase/import`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
}

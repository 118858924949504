import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 8px;

  > .label {
    font-family: "Poppins";
    font-size: 1.25rem;
    line-height: 2rem;
    color: #a79fbc;
    margin: 0;
  }
`;

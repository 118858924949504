import { Colors } from "constants/colors";
import styled from "styled-components";
import { applyOpacityToHexColor } from "utils/applyOpacityToHexColor";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CollapseHeader = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 7px 17px;
  border-radius: 5px;
  background-color: ${Colors.white};
  border: 1px solid #f0f0f0;

  outline: none;
  cursor: pointer;

  z-index: 500;

  &.active {
    background-color: #f0f0f0;
  }
`;

export const CollapseHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  > p {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    margin: 0;
  }
`;

export const TeamLabelContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: ${({ color }) => color};
  background-color: ${({ color }) => applyOpacityToHexColor(color)};
  border-radius: 14px;
  padding: 5px 10px;

  > span {
    font-family: "Open Sans";
    line-height: 10px;
    font-size: 8px;
  }
`;

export const CollapseContent = styled.div`
  background-color: ${Colors.white};
  padding: 20px 16px;
  width: 100%;
  min-height: 100px;
  border: 1px solid #f0f0f0;
  border-radius: 0 0 5px 5px;
  margin-top: -5px;

  display: flex;
  flex-direction: column;
  row-gap: 8px;

  p {
    margin: 0;
  }

  .items {
    display: flex;
    align-items: center;
    column-gap: 4px;

    .item-title {
      font-family: "Cairo";
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #888996;
    }

    .item-description {
      font-family: "Cairo";
      color: #888996;
    }
  }
`;

import {
  ActionsContainer,
  CancelButton,
  ConfirmButton,
  Container,
  Header,
  Option,
  OptionButton,
  OptionsContainer,
} from "./styles";
import { RiDeleteBinFill, RiPencilFill } from "react-icons/ri";
import { Colors } from "constants/colors";
import { Team } from "services/teams/types";
import { useState } from "react";
import { BiTrash } from "react-icons/bi";

interface ModalTeamSettingsProps {
  currentTeam?: Team;
  onDelete: (id: string) => void;
}

function ModalTeamSettings({ currentTeam, onDelete }: ModalTeamSettingsProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    if (!currentTeam) return;

    onDelete(currentTeam._id);
  };

  const handleToggleDeleting = () => {
    setIsDeleting((prev) => !prev);
  };

  if (isDeleting) {
    return (
      <Container>
        <Header>
          <h1>Excluir time</h1>

          <div className="divider"></div>

          <span>{currentTeam?.name}</span>
        </Header>

        <h2>Deseja realmente excluir o time?</h2>

        <ActionsContainer>
          <CancelButton onClick={handleToggleDeleting}>Voltar</CancelButton>
          <ConfirmButton type="button" onClick={handleDelete}>
            <BiTrash color={Colors.white} size={28} />
            Excluir
          </ConfirmButton>
        </ActionsContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Configurações</h1>

        <div className="divider"></div>

        <span>{currentTeam?.name}</span>
      </Header>

      <h2>Selecione abaixo qual das opções você deseja realizar.</h2>

      <OptionsContainer>
        <Option to={`/teams/${currentTeam?._id}/edit`} className="positive">
          <div className="iconWrapper">
            <RiPencilFill color={Colors.success} size={20} />
          </div>
          Editar time
        </Option>
        <OptionButton
          onClick={handleToggleDeleting}
          type="button"
          className="negative"
        >
          <div className="iconWrapper">
            <RiDeleteBinFill color={Colors.error} size={20} />
          </div>
          Excluir time
        </OptionButton>
      </OptionsContainer>
    </Container>
  );
}

export default ModalTeamSettings;

import { getTeamDetail, getTeams } from "services/teams";
import useSWR from "swr";

type TeamListDto = {
  id?: string;
};

export function useTeamsList({ id }: TeamListDto) {
  const url: string = `/team`;

  const { data, error, mutate } = useSWR(url, async () => {
    const data = await getTeams({ id });

    const results = data;

    return results;
  });

  return { data: data ?? { count: 0, results: [] }, error, mutate };
}

export function useTeamDetail(id?: string) {
  const url: string = `/team/${id}`;

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      if (!id) return;

      const data = await getTeamDetail(id);

      return data;
    },
    {
      isPaused: () => !Boolean(id),
    }
  );

  return { data: data, error, mutate };
}

import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  padding: 16px 24px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  > h1 {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: ${Colors.black100};
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
`;

export const ButtonBase = css`
  cursor: pointer;

  max-width: 390px;
  width: 100%;
  padding: 10px 0;

  border-radius: 14px;

  font-family: "Cairo";
  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.2;
  }
`;

export const Button = styled.button`
  ${ButtonBase}

  margin-top: 24px;
  column-gap: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${Colors.white};
  box-shadow: 0px 15px 30px 0px #d4005026;
  background-color: ${Colors.primary100};
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;

  .error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

import { createPortal } from "react-dom";
import { useCallback, useRef, useState } from "react";

import { AiOutlineMail } from "react-icons/ai";

import { useReactToPrint } from "react-to-print";
import html2Canvas from "html2canvas";
import jsPDF from "jspdf";
import { IoMdDownload } from "react-icons/io";

import { Container, ActionButton, ButtonDownload } from "./styles";
import { ButtonPrintTicket } from "pages/Profile/MyTicket/styles";

import print_ticket_icon from "assets/print-ticket-icon.svg";
import { TicketForPrint, TicketPrintType } from "components/TicketForPrint";

import { ResendUserTicket } from "services/Hook/TicketOffice";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import { Colors } from "constants/colors";

interface Contributor {
  id: string;
  name: string;
  email: string;
  whatsapp: string;
  event: string;
  team: string;
  role: string;
}

interface ListTableProps {
  contributors: Contributor[] | undefined;
}

export function TeamsTable({ contributors }: ListTableProps) {
  const [selectedTicket, setSelectedTicket] = useState<TicketPrintType | null>(
    null
  );

  const componentRef = useRef(null);

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [fetchingResendTicket, setFetchingResendTicket] = useState(false);
  const handleResendTicket = async (userTicketId: string) => {
    try {
      setFetchingResendTicket(true);
      await ResendUserTicket({ userTicketId });
      successNotification("Ingresso reenviado com sucesso.");
    } catch {
      errorNotification("Ocorreu um erro ao reenviar o ingresso.");
    } finally {
      setFetchingResendTicket(false);
    }
  };

  const downloadPDF = async (ticket: TicketPrintType) => {
    setSelectedTicket(ticket);

    await sleep(1000);

    const input = componentRef.current;

    if (input) {
      html2Canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;
        pdf.addImage(
          imgData,
          "JPEG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );

        pdf.save(selectedTicket?.event_ticket_id?.name ?? "Ingresso");
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSelectedTicket = useCallback(
    async (ticket: TicketPrintType) => {
      setSelectedTicket(ticket);

      await sleep(1000);

      handlePrint();
    },
    [handlePrint]
  );

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>COLABORADOR</th>
            <th>WHATSAPP</th>
            <th>EVENTO</th>
            <th>TIME</th>
            <th>TIPO DE ACESSO</th>
          </tr>
        </thead>

        <tbody>
          {contributors?.map((item) => {
            return (
              <tr style={{ borderRadius: 20 }} key={item?.id}>
                <td>
                  <h5>{item?.id}</h5>
                </td>

                <td>
                  <div className="row">
                    <div className="column">
                      <h5>{item?.name}</h5>
                      <h6>{item?.email}</h6>
                    </div>
                  </div>
                </td>

                <td>
                  <h5>{item?.whatsapp}</h5>
                </td>

                <td>
                  <h5>{item?.event}</h5>
                </td>

                <td>
                  <h5>{item?.team}</h5>
                </td>

                <td>
                  <h5>{item?.role}</h5>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {createPortal(
        <>
          {selectedTicket ? (
            <TicketForPrint
              ref={componentRef}
              selectedTicket={selectedTicket}
            />
          ) : null}
        </>,
        document.body
      )}
    </Container>
  );
}

import { Colors } from "constants/colors";
import {
  ContainerModal,
  ButtonClear,
  ButtonOk,
  ContainerButtonsModal,
  SelectOption,
  OptionHeader,
} from "./styles";
import { Select } from "antd";
import { ROLES } from "pages/CollaboratorRegister/misc";
import { RiUserSettingsLine } from "react-icons/ri";
import { TeamUsersRoleEnum } from "services/teams/types";
import { useState } from "react";

const { Option } = Select;

interface ModalSelectUserRoleProps {
  onSelect: (role: TeamUsersRoleEnum) => void;
}

function ModalSelectUserRole({ onSelect }: ModalSelectUserRoleProps) {
  const [selectedRole, setSelectedRole] = useState<
    TeamUsersRoleEnum | undefined
  >();

  const handleSelectUserRole = (role: TeamUsersRoleEnum) => {
    setSelectedRole(role);
  };

  const handleApplyUserRole = () => {
    if (!selectedRole) return;

    onSelect(selectedRole);
  };

  const handleClearUserRole = () => {
    setSelectedRole(undefined);
  };

  return (
    <ContainerModal>
      <h1>Selecione o cargo do membro</h1>

      <Select
        optionLabelProp="title"
        placeholder="Insira o tipo de acesso ou selecione um..."
        onChange={(e) => {
          const foundRole = ROLES.find(
            (r) => r.id === (e as unknown as string)
          )?.id;

          if (!foundRole) return;

          handleSelectUserRole(foundRole);
        }}
        value={ROLES.find((r) => r.id === selectedRole)?.title}
      >
        {ROLES.map((role) => (
          <Option key={role.id} value={role.id}>
            <SelectOption>
              <OptionHeader>
                <RiUserSettingsLine color={Colors.primary100} size={20} />
                <strong>{role.title}</strong>
              </OptionHeader>

              <span>{role.description}</span>
            </SelectOption>
          </Option>
        ))}
      </Select>

      <ContainerButtonsModal>
        <ButtonClear onClick={handleClearUserRole}>
          <span>Limpar</span>
        </ButtonClear>

        <ButtonOk onClick={handleApplyUserRole}>
          <span>Aplicar</span>
        </ButtonOk>
      </ContainerButtonsModal>
    </ContainerModal>
  );
}

export default ModalSelectUserRole;

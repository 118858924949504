import { useCallback, useEffect } from "react";

import { RiCalendarCheckFill, RiUserLine } from "react-icons/ri";
import { BiPlus } from "react-icons/bi";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useCreateEvent } from "contexts/CreateEventContext";

import { Colors } from "constants/colors";

import { CreateInformationEventFormData } from "types";

import {
  ButtonAddImage,
  Card,
  Container,
  ContainerImage,
  ContainerImagePreview,
  Divider,
  LabelTitle,
  SubLabelTitle,
  SubTitle,
  Title,
} from "./styles";

import { ButtonNext, ButtonPrevFirst, ContainerButtons } from "../../styles";

import { InputForm } from "components/Form/InputForm";

import ri_image_fill from "assets/ri_image-fill.svg";
import { Link } from "react-router-dom";
import { doCheckEventNicknameAvailability } from "services/Hook/Event";
import { errorNotification } from "components/Notification";

const createInformationEventFormSchema = yup.object().shape({
  name: yup.string().required("Nome do evento obrigatório"),
  image: yup.string().notRequired(),
  nickname: yup.string().required("O Link é obrigatório"),
});

export function BasicInformations() {
  // const [selectedImage, setSelectedImage] = useState<any>(null);

  const { event, file, setFile } = useCreateEvent();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<CreateInformationEventFormData>({
    resolver: yupResolver(createInformationEventFormSchema),
  });

  useEffect(() => {
    reset({
      name: event?.information?.name,
    });

    // if (event?.information?.image) {
    //   setSelectedImage(event?.information?.image);
    // }
  }, [event.information, reset]);

  const isValidNickname = useCallback(async (nickname: string): Promise<boolean> => {
    try {
      await doCheckEventNicknameAvailability(
        nickname
      );

      return false;
    } catch {
      return true;
    }
  }, []);

  const handleAddInformation: SubmitHandler<
    CreateInformationEventFormData
  > = async ({ name, nickname }) => {
    clearErrors(["nickname", "image"]);
    try {
      if (!file) {
        setError("image", { message: "Imagem do evento obrigatória" });

        return;
      }

      const isAvailableNickname = await isValidNickname(
        nickname
      );

      if (!isAvailableNickname) {
        setError("nickname", {
          type: "required",
          message: "Nickname já existe",
        });

        return;
      }

      event.createInformation({
        name,
        image: "",
        nickname,
      });

      event.goToNextStep();
    } catch (error) {
      setError("nickname", {
        type: "required",
        message: "Nickname já existe",
      });

      errorNotification(
        "Erro ao verificar link",
        "Não foi possivel verificar o link, tente novamente"
      );

      console.log({ error });
    }
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileSelected = e.target.files[0];

      setFile(fileSelected);

      // const reader = new FileReader();
      // reader.addEventListener("load", () => {
      //   setSelectedImage(reader.result);
      // });

      // reader.readAsDataURL(file);
    }
  };

  return (
    <Container onSubmit={handleSubmit(handleAddInformation)}>
      <Card>
        <Title>1. Informações Básicas</Title>
        <SubTitle>
          Defina o nome do seu evento e a imagem de capa do mesmo.
        </SubTitle>

        <Divider />

        <LabelTitle>Qual o nome do seu evento?</LabelTitle>

        <InputForm
          isFull
          {...register("name")}
          defaultValue={event?.information?.name}
          error={errors.name}
          icon={<RiCalendarCheckFill size={17} color={Colors.secondary40} />}
          label="Nome do evento"
        />

        <Divider />

        <LabelTitle>Adicione uma imagem de capa</LabelTitle>

        <SubLabelTitle>
          <strong>Especificações: </strong> arquivo do tipo <strong>PNG</strong>{" "}
          ou <strong>JPEG</strong>, com dimensão recomendada de{" "}
          <strong>1580x660</strong> px e tamanho máximo de
          <strong>4MB</strong>.
        </SubLabelTitle>

        <div>
          <input
            id="file-banner"
            type="file"
            style={{ display: "none" }}
            accept="image/png, image/gif, image/jpeg, image/webp, image/jfif"
            onChange={onSelectFile}
          />

          <label className="badge" htmlFor="file-banner">
            <ButtonAddImage>
              <BiPlus size={18} color={Colors.primary100} />

              <span>Adicionar imagem</span>
            </ButtonAddImage>
          </label>
        </div>

        {!file ? (
          <ContainerImage>
            <img src={ri_image_fill} alt="" />
          </ContainerImage>
        ) : null}

        {file ? (
          <ContainerImagePreview>
            <img src={URL.createObjectURL(file)} alt="Thumb" />
          </ContainerImagePreview>
        ) : null}

        {!!errors?.image?.message ? (
          <span className="error">{errors?.image?.message}</span>
        ) : null}

        <Divider />

        <InputForm
          isFull
          {...register("nickname")}
          mask="especial"
          hasPrefix
          prefix="eventx.com.br/"
          icon={<RiUserLine size={17} color={Colors.secondary40} />}
          error={errors.nickname}
          label="Link Eventx"
          defaultValue={event?.information?.nickname}
        />
      </Card>

      <ContainerButtons>
        <Link to="/my-event">
          <ButtonPrevFirst type="button" onClick={event.goToBackStep}>
            <span>Voltar</span>
          </ButtonPrevFirst>
        </Link>

        <ButtonNext type="submit">
          <p>Prosseguir</p>
        </ButtonNext>
      </ContainerButtons>
    </Container>
  );
}
